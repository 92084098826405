/* // #########################################################################
: m.services.js
: 10.March.2022
: Deals with maintaining services code.
:
/**/// ########################################################################
/** @module services */

import { Utils } from './m.utils.js';

/**/// ########################################################################
/**
 * Services class, manages repeating micro code.
 */
 class cServices
 {
     constructor()
     {
         this.list = [];
     }

     add(label, func, timer, param)
     {
         let entry = {};

         entry.active = true;
         entry.runs = 0;
         entry.maxtime = null;
         entry.mintime = null;
         entry.totaltime = 0;
         entry.label = label;
         entry.func = func;
         entry.timer = timer;
         entry.handle = null;   // interval handle
         entry.param = param;

         this.list[label] = entry;
         this.resume(label);
     }

     pause(label)
     {
         let entry = this.list[label];
         entry.active = false;
         if(entry.handle)
         {
             clearInterval(entry.handle);
             entry.handle = null;
         }
     }

     resume(label)
     {
         let entry = this.list[label];
         entry.active = true;
         let _this = this;
         entry.handle = setInterval(function() { _this.exec(label); }, entry.timer);
     }

     exec(label)
     {
        let entry = this.list[label];
        if(!entry.active) return;

        let ts_before = Utils.getTimestamp();
        entry.func(entry);
        let ts_after = Utils.getTimestamp();
        let elapsed = ts_after - ts_before;

        entry.runs++;
        entry.totaltime += elapsed;

        if(entry.maxtime === null) entry.maxtime = elapsed;
        if(entry.mintime === null) entry.mintime = elapsed;

        if(entry.maxtime < elapsed) entry.maxtime = elapsed;
        if(entry.mintime > elapsed) entry.mintime = elapsed;
     }



 } // cServices
 
 
 /**/// ########################################################################
 export let Services = new cServices();
 
 /**/// ########################################################################
 