/* // #########################################################################
: m.utils.js
: 06.March.2022
: Agregates useful functions.
:
/**/// ########################################################################
/** @module utils */

/**/// ########################################################################
class cUtils
{
  constructor()
  {
    this.getTimestamp = function()
    {
      return new Date().getTime();
    };

    if(window.performance.now)
    {
      this.getTimestamp = function()
      {
        return window.performance.now();
      };
    } else {
      if(window.performance.webkitNow)
      {
        this.getTimestamp = function()
        {
          return window.performance.webkitNow();
        };
      }
    }
  }

  /**
     *
     * @returns A hex random color.
     */
  randomHexColor ()
  {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  /**
     * Prints an info message to the console.
     * @param {string} msg
     */
  info(msg)
  {
    const styleA = [
      'color: #fff',
      'background-color: black',
      'font-size: 16px',
      'padding: 4px 6px',
      'border-radius: 6px 0px 0px 6px',
      'margin-right: 0px'
    ].join(';');

    const styleB = [
      'color: white',
      'background-color: #00f',
      'font-size: 16px',
      'padding: 4px 4px',
      'border-radius: 0px 6px 6px 0px',
      'margin-right: 2px',
      'margin-left: -2px'
    ].join(';');

    const styleC = [
      'color: white',
      'background-color: #555',
      'font-size: 16px',
      'padding: 4px 10px',
      'border-radius: 4px'
    ].join(';');

    console.log('%c%s%c%s%c%s', styleA, 'J', styleB, 'i', styleC, msg);
  }

  /**
     * Prints a Warning message to the console.
     * @param {string} msg
     */
  warning(msg)
  {
    const styleA = [
      'color: #fff',
      'background-color: black',
      'font-size: 16px',
      'padding: 4px 6px',
      'border-radius: 10px 0px 0px 10px',
      'margin-right: 0px'
    ].join(';');

    const styleB = [
      'color: black',
      'background-color: #ee0',
      'font-size: 16px',
      'padding: 4px 4px',
      'border-radius: 0px 10px 10px 0px',
      'margin-right: 2px',
      'margin-left: -2px'
    ].join(';');

    const styleC = [
      'color: white',
      'background-color: #555',
      'font-size: 16px',
      'padding: 4px 10px',
      'border-radius: 4px'
    ].join(';');

    console.log('%c%s%c%s%c%s', styleA, 'J', styleB, '!', styleC, msg);
  }

  /**
   *
   * @param {String} msg
   */
  error(msg)
  {
    const styleA = [
      'color: #fff',
      'background-color: black',
      'font-size: 16px',
      'padding: 4px 6px',
      'border-radius: 10px 0px 0px 10px',
      'margin-right: 0px'
    ].join(';');

    const styleB = [
      'color: white',
      'background-color: #f00',
      'font-size: 16px',
      'padding: 4px 4px',
      'border-radius: 0px 10px 10px 0px',
      'margin-right: 2px',
      'margin-left: -2px'
    ].join(';');

    const styleC = [
      'color: white',
      'background-color: #555',
      'font-size: 16px',
      'padding: 4px 10px',
      'border-radius: 4px'
    ].join(';');

    console.log('%c%s%c%s%c%s', styleA, 'J', styleB, 'e', styleC, msg);
  }

  /**
     * Prints to the console the engine version.
     * @param {Array} version
     */
  printVersion (version)
  {
    const styleA = [
      'color: #fff',
      'background-color: black',
      'font-size: 40px',
      'line-height: 40px',
      'padding: 3px 9px',
      'border-radius: 10px',
      'margin-right: 2px',
      'border: 4px solid #000'
    ].join(';');

    const styleB = [
      'color: #333',
      'background-color: #F99',
      'font-size: 40px',
      'line-height: 40px',
      'padding: 4px',
      'border-radius: 6px',
      'border: 4px solid #000',
      'text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 15px rgba(55,55,55,0.3)'
    ].join(';');

    const msg = '♤♡ Joker Game Engine ♧♢';
    console.log('%c%s%c%s', styleA, '🂿', styleB, msg);

    this.info(`Joker Version: v${version[0]}.${version[1]}.${version[2]}`);
  }
}

/**/// ########################################################################
export const Utils = new cUtils();

/**/// ########################################################################
class cTimer
{
  constructor()
  {
    this.reset();
  }

  /**
   * reset measurements taken
   */
  reset()
  {
    this.runs = 0;
    this.mintime = null; // ms
    this.maxtime = null; // ms
    this.avgtime = null; // ms
    this.totaltime = 0; // ms
    this.elapsed = 0; // ms
  }

  /**
   * start the measuring period
   */
  start()
  {
    this.ts_start = Utils.getTimestamp();
  }

  /**
   * end the measuring period
   */
  end()
  {
    this.ts_end = Utils.getTimestamp();
    this.runs++;

    this.elapsed = this.ts_end - this.ts_start;
    this.totaltime += this.elapsed;

    if (this.mintime === null) this.mintime = this.elapsed;
    if (this.maxtime === null) this.maxtime = this.elapsed;

    if (this.mintime > this.elapsed) this.mintime = this.elapsed;
    if (this.maxtime < this.elapsed) this.maxtime = this.elapsed;

    this.avgtime = this.totaltime / this.runs;
  }
}

/**
 * Returns human-readable byte ammount
 * @param {number} val
 * @returns string - value but in human readable bytes
 */
function byteVal(val)
{
  let suf = 'b';
  if(val > 1024) { val /= 1024; suf = 'kb' };
  if(val > 1024) { val /= 1024; suf = 'mb' };
  if(val > 1024) { val /= 1024; suf = 'gb' };
  if(val > 1024) { val /= 1024; suf = 'tb' };

  return `${Math.round(val)}${suf}`;
}

/** ###########################################################################
 * Generates a random unique id:
 */
function uuidv4()
{
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

/**/// ########################################################################
/*
    cyrb53 (c) 2018 bryc (github.com/bryc)
    A fast and simple hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
*/
function cyrb53(str, seed = 0)
{
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++)
  {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

/**/// ########################################################################
module.exports.cTimer = cTimer;
module.exports.cyrb53 = cyrb53;
module.exports.uuidv4 = uuidv4;
module.exports.byteVal = byteVal;
