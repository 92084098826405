/* // #########################################################################
: m.res.wasm.js
: 01.Aug.2022
: Resource class for WebAssembly code files
:
/**/// ########################################################################
/** @module res.wasm.common */

import { cResourceBase, ResState } from '../m.resource.js'
import { fetchCompileWasm } from '../m.webasm.js'

/**/// ######################################################################## obj
class rWasm extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  async build()
  {
    this.state = ResState.LOADING;
    this.active = false;

    this.wasm = await fetchCompileWasm(this.rawData);

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged(dep)
  {
    console.log('object wasm evSubChanged');
    console.log(dep.type);
    console.log(dep.url);

    this.state = ResState.LOADING;
    this.state = ResState.VALIDATED;
  }
}

/**/// ########################################################################
module.exports.rWasm = rWasm;
/**/// ########################################################################
