/* // #########################################################################
: m.res.vertexshader.js
: 18.May.2022
: Resource class for Vertex Shader under WebGL2
:
/**/// ########################################################################
/** @module res.vertexshader.webgl2 */

import { cResourceBase, ResState } from '../m.resource.js'
import { Utils } from '../m.utils.js'

/**/// ########################################################################
class rGL2VertexShader extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.rawToString();
    this.type = 'vs';
    this.gl = options.gl;

    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  build()
  {
    this.meta = {};
    this.meta.source = this.rawData;
    this.meta.shader = this.gl.createShader(this.gl.VERTEX_SHADER);
    this.gl.shaderSource(this.meta.shader, this.meta.source);
    this.gl.compileShader(this.meta.shader);

    if (!this.gl.getShaderParameter(this.meta.shader, this.gl.COMPILE_STATUS))
    {
      const msg = 'Vertex Shader failed to compile: ' + this.gl.getShaderInfoLog(this.meta.shader);
      this.state = ResState.FAILED;
      Utils.error(msg);
      throw Error(msg);
    }

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  rebuild()
  {
    throw Error('rebuild request on vertex shader resource');
  }
}

/**/// ########################################################################
module.exports.rGL2VertexShader = rGL2VertexShader;
/**/// ########################################################################
