/* // #########################################################################
: m.res.fragmentShader.js
: 18.May.2022
: Resource class for Fragment Shader under WebGL2
:
/**/// ########################################################################
/** @module res.vertexshader.webgl2 */

import { cResourceBase, ResState } from '../m.resource.js'
import { Utils } from '../m.utils.js'

/**/// ######################################################################## fs
class rGL2FragmentShader extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.gl = options.gl;
    this.type = 'fs';
    this.rawToString();
    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  build()
  {
    this.meta = {};
    this.meta.source = this.rawData;
    this.meta.shader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
    this.gl.shaderSource(this.meta.shader, this.meta.source);
    this.gl.compileShader(this.meta.shader);

    if (!this.gl.getShaderParameter(this.meta.shader, this.gl.COMPILE_STATUS))
    {
      const msg = 'Fragment Shader failed to compile: ' + this.gl.getShaderInfoLog(this.meta.shader);
      Utils.error(msg);
      throw Error(msg);
    }

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged()
  {
    throw Error('rebuild request on fragment shader resource');
  }
}

/**/// ########################################################################
module.exports.rGL2FragmentShader = rGL2FragmentShader;
/**/// ########################################################################
