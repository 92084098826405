/* // #########################################################################
: m.events.js
: 04.March.2022
: Deals with creating and maintaining engine-wide events.
:
/**/// ########################################################################
/** @module events */

/*
TODO: See use of window dispatch events
more: https://dev.to/tqbit/how-to-monitor-the-progress-of-a-javascript-fetch-request-and-cancel-it-on-demand-107f

/**/// ########################################################################
/**
 * Events class, manages engine-wide event signaling.
 */
class cEvents
{
  constructor()
  {
    this.events = [];
  }

  /**
   * Subscribes to an event
   * @param {string} eventName
   * @param {string} funcName
   * @param {function} func
   * @returns nothing
   */
  sub(eventName, funcName, func)
  {
    // check if the event is already present:
    if (!(eventName in this.events))
    {
      this.events[eventName] = [];
    }

    const list = this.events[eventName];
    if (funcName in list)
    {
      // already has this func
      console.log('Event already registered');
      console.log(`Event: ${eventName} ${funcName}`);
      return;
    }

    // does not have this func, add it
    const entry = {};
    entry.eventName = eventName;
    entry.label = funcName;
    entry.func = func;
    entry.count = 0;
    entry.active = true;

    list[funcName] = entry;
  } // sub

  /**
   * Unsubscribe from an event.
   * @param {string} eventName
   * @param {string} funcName
   */
  unsub(eventName, funcName)
  {
    if ((eventName in this.events))
    {
      if ((funcName in this.events[eventName]))
      {
        delete this.events[eventName][funcName];
      }
    }
  } // unsub

  /**
   * Triggers a specific event, runs all of the code hooked to that event.
   * @param {string} eventName
   * @param {*} params
   */
  trigger(eventName, params)
  {
    if (!params) params = {};

    // check if the event is already present:
    if (this.events[eventName])
    {
      const list = this.events[eventName];

      for (const [, entry] of Object.entries(list))
      {
        if (!entry.active) continue;
        entry.func(params);
        entry.count++;
      }
    }

    if (eventName !== '*')
    {
      params.eventName = eventName;
      this.trigger('*', params);
    }
  } // trigger
}

/**/// ########################################################################
export const Events = new cEvents();
/**/// ########################################################################
