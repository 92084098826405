/**/// ########################################################################
/**
 * @file Deals with IndexedDB access
 * @author Hugo Ferreira © 05.September.2022
 */
/**/// ########################################################################
/** @module browser.indexedDB */

/** ###########################################################################
 * class cIndexedDB, fine wrapper over IndexedDB functionality
 */
class cIndexedDB
{
  constructor(name, version)
  {
    this.openRequest = indexedDB.open(name, version);
    this.isOpen = false;

    const _this = this;

    // Perform DB Initialization:
    this.openRequest.onupgradeneeded = function()
    {
      _this.db = _this.openRequest.result;
      if(!_this.db.objectStoreNames.contains('res'))
      { // Doesnt exist, create it:
        _this.db.createObjectStore('res', { keyPath: 'hurl' });
      }
    };

    this.openRequest.onerror = function()
    {
      console.error('Error', _this.openRequest.error);
    };

    this.openRequest.onsuccess = function() {
      _this.isOpen = true;
      _this.db = _this.openRequest.result;
    };
  }

  /** ###########################################
   * Add a row to a specific table
   * @param {string} table
   * @param {object} vals
   * @returns promise
   */
  add(table, vals)
  {
    const _this = this;
    return new Promise((resolve, reject) => {
      const tx = _this.db.transaction(table, 'readwrite');

      // get an object store to operate on it
      const os = tx.objectStore(table);
      const request = os.add(vals);

      request.onsuccess = function()
      {
        return resolve(true);
      };

      request.onerror = function()
      {
        reject(request.error);
      };
    });
  }

  /** ###########################################
   * Adds a row to a specific table, if key already exists, overwrites
   * @param {string} table
   * @param {object} vals
   * @returns promise
   */
  put(table, vals)
  {
    const _this = this;
    return new Promise((resolve, reject) => {
      const tx = _this.db.transaction(table, 'readwrite');

      // get an object store to operate on it
      const os = tx.objectStore(table);
      const request = os.put(vals);

      request.onsuccess = function()
      {
        return resolve(true);
      };

      request.onerror = function()
      {
        reject(request.error);
      };
    });
  }

  /** ###########################################
   * Gets a row from a table via its key
   * @param {string} table
   * @param {number|string} key
   * @returns Promise
   */
  get(table, key)
  {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(table, 'readonly');
      const os = tx.objectStore(table);
      const request = os.get(key);

      request.onsuccess = function()
      {
        resolve(request.result);
      };

      request.onerror = function()
      {
        reject(request.error);
      };
    });
  }

  /** ###########################################
   * Returns all the rows from a specific table
   * @param {string} table
   * @returns Promise
   */
  getAll(table)
  {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(table, 'readonly');
      const os = tx.objectStore(table);
      const request = os.getAll();

      request.onsuccess = function()
      {
        resolve(request.result);
      };

      request.onerror = function()
      {
        reject(request.error);
      };
    });
  }

  /** ###########################################
   * Deletes a row from a table via its key
   * @param {string} table
   * @param {string|number} key
   * @returns Promise
   */
  delete(table, key)
  {
    return new Promise((resolve, reject) => {
      const tx = this.db.transaction(table, 'readwrite');
      const os = tx.objectStore(table);
      const request = os.delete(key);

      request.onsuccess = function()
      {
        resolve(true);
      };

      request.onerror = function()
      {
        reject(request.error);
      };
    });
  }

  /** ###########################################
   * Deletes all rows from a specific table
   * @param {string} table
   */
  truncate(table)
  {

  }
}

/**/// ########################################################################
let varExport = null;
if(window.indexedDB)
{
  varExport = new cIndexedDB('joker', 1);
}
export const IndexedDB = varExport;
/**/// ########################################################################
