/* // #########################################################################
: m.inputs.js
: 25.May.2022
: Manages game input sources:
: Mouse, Keyboard, Touch, Controller
:
/**/// ########################################################################
/** @module inputs */

import { Events } from './m.events.js';

/** ###########################################################################
 *
 */
class cInputs
{
  constructor()
  {
    const _this = this;
    this.keydown = {};
    this.point = {
      pressed: false,
      context: false,
      drag: false,
      x: 0,
      y: 0,
      dragX: 0,
      dragY: 0
    };

    // Mouse Right Button
    document.addEventListener('contextmenu', (ev) => {
      Events.trigger('mouse_right_button');
      _this.point.context = true;
      ev.preventDefault();
      return false;
    }, false);

    // Mouse/Touch Move:
    document.addEventListener('mousemove', (ev) => {
      _this.point.x = ev.x;
      _this.point.y = ev.y;

      if((_this.point.pressed) && !(_this.point.drag))
      {
        _this.point.dragX = ev.x;
        _this.point.dragY = ev.y;
        Events.trigger('drag_start');
        _this.point.drag = true;
      }

      return false;
    }, false);

    document.addEventListener('touchmove', (ev) => {
      if(ev.touches.length > 0)
      {
        _this.point.pressed = true;
        _this.point.x = ev.touches[0].clientX;
        _this.point.y = ev.touches[0].clientY;

        if(!_this.point.drag)
        {
          _this.point.drag = true;
          _this.point.dragX = ev.touches[0].clientX;
          _this.point.dragY = ev.touches[0].clientY;
          Events.trigger('drag_start');
        }
      }

      ev.stopPropagation();
      return false;
    }, false);

    // Mouse/Touch Down:
    document.addEventListener('mousedown', (ev) => {
      _this.point.x = ev.x;
      _this.point.y = ev.y;

      if('button' in ev)
      {
        if(ev.button === 0)
        {
          _this.point.pressed = true;
        }
      } else {
        if('which' in ev)
        {
          if(ev.which === 0)
          {
            _this.point.pressed = true;
          }
        }
      }

      return false;
    }, false);

    // Touch Start:
    document.addEventListener('touchstart', (ev) => {
      if(ev.touches.length)
      {
        _this.point.pressed = true;
        _this.point.x = ev.touches[0].clientX;
        _this.point.y = ev.touches[0].clientY;
      }

      ev.stopPropagation();
      return false;
    }, false);

    // Mouse Up:
    document.addEventListener('mouseup', (ev) => {
      _this.point.x = ev.x;
      _this.point.y = ev.y;

      if('button' in ev)
      {
        if(ev.button === 0)
        {
          _this.point.pressed = false;
        } else {
          _this.point.context = false;
        }
      } else {
        if('which' in ev)
        {
          if(ev.which === 0)
          {
            _this.point.pressed = false;
          } else {
            _this.point.context = false;
          }
        }
      }

      if(_this.point.drag)
      {
        _this.point.drag = false;
        Events.trigger('drag_end');
      }
      return false;
    }, false);

    document.addEventListener('touchend', (ev) => {
      if(ev.touches.length === 0)
      {
        _this.point.pressed = false;
        if(_this.point.drag)
        {
          _this.point.drag = false;
          Events.trigger('drag_end');
        }
      }

      ev.stopPropagation();
      return false;
    }, false);

    // Mouse Wheel
    document.addEventListener('wheel', (event) => {
      const arg = { deltaX: event.deltaX, deltaY: event.deltaY };
      Events.trigger('mouse_wheel', arg);
      return false;
    }, false);

    // Keydown:
    document.addEventListener('keydown', (event) => {
      this.keydown[event.code] = true;
    }, false);

    // Keyup:
    document.addEventListener('keyup', (event) => {
      this.keydown[event.code] = false;
    }, false);
  }

  registerAction()
  {

  }
}

/**/// ########################################################################
export const Inputs = new cInputs();
/**/// ########################################################################
