/* // #########################################################################
: m.renderable.js
: 28.Aug.2022
:
/**/// ########################################################################
/** @module rRenderable */

/**/// ########################################################################
import { cResourceBase, Resources, ResState } from '../m.resource.js'

/**/// ########################################################################
/**
 * Resource Renderable
 */
class rRenderable extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.obj = options.obj;
    this.type = 'rend';

    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  init()
  {
    this.meta = { firstPass: true, vars: [] };

    // Position, Rotation:
    this.meta.pos = { x: 0.0, y: 0.0, z: 0.0 };
    this.meta.rot = { x: 0.0, y: 0.0, z: 0.0 };

    this.opacity = 1.0;

    this.pickable = false;
    this.pickcolor = [0, 0, 0];

    Resources.register(this);
    Resources.link(this.id, this.obj.id);

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged(dep)
  {
    this.meta.firstPass = true;
    this.meta.vars = [];

    this.state = ResState.VALIDATED;
    this.active = true;
  }
}

/**/// ########################################################################
module.exports.rRenderable = rRenderable;
/**/// ########################################################################
