/* // #########################################################################
: m.cookie.js
: 30.March.2022
:
: Responsability:
:   Manages setting/getting of cookie values.
:
/**/// ########################################################################
/** @module cookie */

/**/// ########################################################################
class cCookie
{
  /**
   * Sets a cookie entry.
   * @param {string} label
   * @param {string} val
   * @param {Number} days
   */
  set(label, val, days)
  {
    days ??= 365;
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${label}=${val};expires=${d.toUTCString()};path=/`;
  }

  /**
   * Returns the value of a cookie entry.
   * @param {string} label
   * @returns Value of cookie entry.
   */
  get(label)
  {
    const name = `${label}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++)
    {
      let c = ca[i];
      while (c.charAt(0) === ' ')
      {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0)
      {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  /**
   * Iterates over all set Cookies
   * @param {function} fptr
   */
  for_each(fptr)
  {

  }
}

/**/// ########################################################################
export const Cookie = new cCookie();

/**/// ########################################################################
