/* // #########################################################################
: m.registry.js
: 04.March.2022
: Manages the registry of specific issues, features, etc.
:
/**/// ########################################################################
/** @module registry */

/**/// ########################################################################
class cRegistry
{
  constructor()
  {
    this.set_defaults();
    this.detect_mobile();
    this.detect_isSecure();
  }

  /**
   * Set Registry initial defaults.
   */
  set_defaults()
  {
    this.hasRenderer = false;
    this.hasFocus = false;
    this.isSecure = false;
    this.isMobile = false;
  }

  /**
   * Tries to detect a touch-based device.
   */
  detect_mobile()
  {
    let hasTouchScreen = false;
    if ('maxTouchPoints' in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        hasTouchScreen = (
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
      }
    }

    this.isMobile = hasTouchScreen;
  }

  /**
   * Determines if we are under an https url.
   */
  detect_isSecure()
  {
    this.isSecure = false;
    if (window.isSecureContext) {
      this.isSecure = true;
    }
  }
}

/**/// ########################################################################
export const Registry = new cRegistry();
/**/// ########################################################################
