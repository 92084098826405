/* // #########################################################################
: m.res.camera.js
: 14.July.2022
: Generic Class to handle camera work in the engine, across renderers.
:
/**/// ########################################################################
/** @module renderCamera */

/**/// ########################################################################
import { mat4, vec3 } from '../../../external/glmatrix/gl-matrix-min.js'

/**/// ########################################################################
/**
 * Render Class specialized for WebGL
 * @extends cRenderCamera
 */
class cRenderCamera
{
  constructor()
  {
    this.needsUpdate = true;
    this.pos = { x: 0.0, y: 0.0, z: 0.0 };
    this.target = { x: 0.0, y: 0.0, z: 0.0 };
    this.upVec = { x: 0.0, y: 1.0, z: 0.0 };
    this.matrix = mat4.create();
  }

  setPos(x, y, z)
  {
    this.pos = { x, y, z };
    this.needsUpdate = true;
  }

  setTarget(x, y, z)
  {
    this.target = { x, y, z };
    this.needsUpdate = true;
  }

  updateMatrix()
  {
    if(!this.needsUpdate) return;
    this.needsUpdate = false;

    mat4.lookAt(this.matrix,
      // Point where the camera is
      vec3.fromValues(this.pos.x, this.pos.y, this.pos.z),
      // Point camera is looking at:
      vec3.fromValues(this.target.x, this.target.y, this.target.z),
      // Up Vector:
      vec3.fromValues(this.upVec.x, this.upVec.y, this.upVec.z)
    );
  }
}

/**/// ########################################################################
module.exports.cRenderCamera = cRenderCamera;
/**/// ########################################################################
