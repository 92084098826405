/** ###########################################################################
 * @file Extends the base Engine class
 * @author © Hugo Ferreira - 29.April.2022
 */
/**/// ########################################################################
import { config } from '../../config/config.js'
import { cEngineBase } from '../../joker/client/m.engine.js'
import { ResState } from '../../joker/client/m.resource.js'
import { cTimer, byteVal } from '../../joker/client/m.utils.js'
import { rRenderable } from '../../joker/client/res_common/m.renderable.js'

// 

/** @module engine */

/** ###########################################################################
 * class cEngine
 */
class cEngine extends cEngineBase
{
  constructor()
  {
    super();
    // 
  }

  /**
   * Calls the base class initialization and performs other init code
   */
  init()
  {
    super.init();
    this.timer = new cTimer();

    // 

    // DELETE:
    this.obj = {};
    this.obj.rot = { x: -3.14/2, y: 0, z: 0 };
    //this.obj.rot = { x: 0.0, y: 0, z: 0 };
    
    this.inputSetup();

    // Create sections
    this.Browser.debug('top', '');
    this.Browser.debug('info', '');
    this.Browser.debug('error', '');

    this.Browser.debug(5000, `webASM Suport: ${this.Registry.support.webASM}`);
    this.Browser.debug(5000, `web Workers Suport: ${this.Registry.support.webWorkers}`);
    this.Browser.debug(5000, `LocalStorage Suport: ${this.Registry.support.localStorage}`);
    this.Browser.debug(5000, `IndexedDB Suport: ${this.Registry.support.indexedDB}`);

    this.objNum = 1;
  }

  /**
   * Sets up the inputs
   */
  inputSetup()
  {
    this.dragZ = this.dragX = 0.0;

    this.Events.sub('mouse_wheel', 'main_input_mouse_wheel', (arg) => {
      this.obj.rot.z += (arg.deltaY / 500);
    });

    this.Events.sub('drag_start', 'main_input_drag_start', (arg) => {
      this.dragZ = this.obj.rot.z;
      this.dragX = this.obj.rot.x;
    });
  }

  /**
   * Calls the base class launch and performs other launch code
   */
  launch()
  {
    super.launch();
    if(config.gameserver.active)
    {
      // 
    }
  }

  /**
   * Loads the resources and allows us to track the progress
   */
  async loadResources()
  {
    // <section=prod>
    await this.Resources.load('local://{#render#}.game.pak');
    // </section=prod>

    // 
    
    this.robj = await this.Resources.load(`local://{#render#}/earth.obj`);

    let r = new rRenderable({ parse: true, obj: this.robj });
    r.meta.pos = { x: 0.0, y: 0.0, z: -1.5 };
    r.meta.rot = { x: 0.0, y: 0.0, z: 0.0 };

    if('storage' in navigator)
    {
      this.Browser.debug(5000, 'Storage is present in navigator');
    }

    if(('storage' in navigator) && ('estimate' in navigator.storage))
    {
      const quota = await navigator.storage.estimate();      
      this.Browser.debug(15000, `TotalSpace: ${byteVal(quota.quota)}`);
      this.Browser.debug(15000, `UsedSpace: ${byteVal(quota.usage)}`);
    }
    else
    {
      this.Browser.debug(5000, 'IndexedDB: Could not access estimation API.');
    }
  }
  
  /**
   * respond to input states
   */
  inputHandle()
  {
    // Rotate Camera
    if(this.Inputs.keydown['KeyW']) { this.render.camera.pos.y += 0.01; }
    if(this.Inputs.keydown['KeyS']) { this.render.camera.pos.y -= 0.01; }
    if(this.Inputs.keydown['KeyA']) { this.render.camera.pos.x += 0.01; }
    if(this.Inputs.keydown['KeyD']) { this.render.camera.pos.x -= 0.01; }
    if(this.Inputs.keydown['KeyQ']) { this.render.camera.pos.z += 0.01; }
    if(this.Inputs.keydown['KeyE']) { this.render.camera.pos.z -= 0.01; }

    this.render.camera.needsUpdate = true;

    // Move light
    if(this.Inputs.keydown['KeyI']) { this.render.light.y += 0.1; }
    if(this.Inputs.keydown['KeyK']) { this.render.light.y -= 0.1; }
    if(this.Inputs.keydown['KeyJ']) { this.render.light.x -= 0.1; }
    if(this.Inputs.keydown['KeyL']) { this.render.light.x += 0.1; }
    if(this.Inputs.keydown['KeyU']) { this.render.light.z += 0.1; }
    if(this.Inputs.keydown['KeyO']) { this.render.light.z -= 0.1; }

    // Rotate objects
    if(this.Inputs.keydown['KeyZ']) { this.obj.rot.x -= 0.01; }
    if(this.Inputs.keydown['KeyX']) { this.obj.rot.x += 0.01; }
    if(this.Inputs.keydown['KeyC']) { this.obj.rot.y -= 0.01; }
    if(this.Inputs.keydown['KeyV']) { this.obj.rot.y += 0.01; }
    if(this.Inputs.keydown['KeyB']) { this.obj.rot.z -= 0.01; }
    if(this.Inputs.keydown['KeyN']) { this.obj.rot.z += 0.01; }

    if(this.Inputs.point.drag)
    {
      let x = this.Inputs.point.dragX - this.Inputs.point.x;
      let y = this.Inputs.point.dragY - this.Inputs.point.y;
      this.obj.rot.z = this.dragZ + (-x / 100.0);
      this.obj.rot.x = this.dragX + (-y / 100.0);
    }
  }

  /**
   * Rotate the scene, rotate an object
   */
  animate()
  {
    this.inputHandle();

    this.xxx = null;

    this.Resources.forEach((res) => {
      if(res.type !== 'wasm') return;
      if(res.state !== ResState.VALIDATED) return;
      if(!res.active) return;

      this.xxx = res;
    });

    if(this.xxx !== null)
    {
      this.obj.rot.z = this.xxx.wasm.move(this.obj.rot.z);
    }
    else
    {
      this.obj.rot.z += 0.0025;
    }

    // this.obj.rot.z = (this.frameElapsed % 628318) / 10000;
    
    this.Resources.forEach((res) => {
      if(res.type !== 'rend') return;
      if(res.state !== ResState.VALIDATED) return;
      if(!res.active) return;

      res.meta.rot.x = this.obj.rot.x;
      res.meta.rot.y = this.obj.rot.y;
      res.meta.rot.z = this.obj.rot.z;
    }); // forEach
  }

  /**
   * Renders a single frame
   */
  render_frame()
  {
    this.timer.start();
    this.animate();
    this.render.render();
    this.timer.end();

    this.val = Math.floor(this.timer.avgtime * 1000);
    this.Browser.debug('info', `RenderAvg: ${this.val}µs <br>Frames: ${this.timer.runs}`);
  }
} // cEngine

/**/// ########################################################################
export const Engine = new cEngine();
/**/// ########################################################################
