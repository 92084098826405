/**
 * @file Deals with browser feature support.
 * @author Hugo Ferreira © 11.September.2022
 */
/**/// ########################################################################
/** @module browser.support */

/**
 * This class takes care of detecting browser support.
 */
class cBrowserSupport
{
  /**
   * Determines if a webp specific feature is suported
   * @param {*} feature - one of 'lossy', 'lossless', 'alpha' or 'animation'.
   * @param {*} callback - callback(feature, isSupported)
   */
  supports_webp(feature, callback)
  {
    const kTestImages = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
      lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
      alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
      animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
    };
    const img = new Image();
    img.onload = function () {
      const result = (img.width > 0) && (img.height > 0);
      callback(feature, result);
    };
    img.onerror = function () {
      callback(feature, false);
    };
    img.src = 'data:image/webp;base64,' + kTestImages[feature];
  }

  /**
   * Detects webASM suport
   * @returns true if webASM is suported
   */
  supports_webASM()
  {
    return (() => {
      try {
        if (typeof WebAssembly === 'object' &&
                 typeof WebAssembly.instantiate === 'function') {
          const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
          if (module instanceof WebAssembly.Module)
          { return new WebAssembly.Instance(module) instanceof WebAssembly.Instance; }
        }
      } catch (e) {
      }
      return false;
    })();
  }

  /**
   * Determins indexedDB suport
   * @returns true if indexedDB is suported
   */
  supports_indexedDB()
  {
    if(window.indexedDB)
    {
      return true;
    }

    return false;
  }

  /**
   * Determines suport for local Storage
   * @returns true if local Storage is suported
   */
  supports_localStorage()
  {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch(e) {
      return false;
    }
  }

  /**
   * Determines if this device supports web workers
   * @returns boolean true if this device supports web workers
   */
  supports_web_workers()
  {
    if(typeof (Worker) === 'undefined') return false;
    return true;
  }
}

/**/// ########################################################################
export const BrowSupport = new cBrowserSupport();
/**/// ########################################################################
