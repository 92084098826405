/* // #########################################################################
: m.progfetch.js
: 17.Aug.2022
: Progressive fetch implementation.
:
/**/// ########################################################################
/** @module progressiveFetch */

import { Cookie } from './m.cookie.js';
import { IndexedDB } from './m.browser.indexedDB.js'
import { Browser } from './m.browser.js';

// 

/* // ########################################################################
[ ] return ArrayBuffer

/* // ########################################################################
options entries :
cb: (perc) => {}
# callback function, invoked with the current percentage of download
# invoked at 0 percent
# invoked at 100 percent

dataTotal: 0
# override the downloaded data total size, into a known value

/**/// ########################################################################
/**
 * Progressive fetch implementation
 * @param {string} url
 * @param {Object} options
 * returns a promise, which returns an arraybuffer
 */
function progressiveFetch(url, options)
{
  return new Promise((resolve, reject) => {
    options ??= {};
    options.cb ??= () => {};
    options.cache ??= 'default';

    // const date = new Date('Sun, 04 Sep 2022 23:50:42 GMT');
    // const ETag = Cookie.get('gamepaketag');
    const cache = options.cache; // default / only-if-cached
    let mode;

    if(options.cache === 'only-if-cached')
    {
      mode = 'same-origin';
    }

    fetch(url, {
      cache, // see: https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
      mode
      // headers: {
      //   'Cache-Control': 'max-age=120',
      //   ETag
      //   //'If-Modified-Since': date.toUTCString()
      // }
    }).then((response) => {
      const status = response.status;
      // console.log(`# status: ${status}`);
      if(status === 200)
      {
        const reader = response.body.getReader();
        let dataTotal = +response.headers.get('content-length');
        if('dataTotal' in options) dataTotal = options.dataTotal; // override

        // const etag = response.headers.get('etag');
        // Cookie.set('gamepaketag', etag);

        let received = 0;
        const chunks = [];

        // Initial callback at zero percent:
        let perc = 0;
        options.cb(perc);

        // Setup Callback Intervals:
        const cbHandle = setInterval(() => {
          options.cb(perc);
        }, 250);

        const whileRead = () => {
          reader.read().then(response => {
            if(!response.done)
            {
              chunks.push(response.value);
              received += response.value.length;

              // Calculate percentage done and invoke callback:
              perc = Math.round((received * 100.0) / dataTotal);
              whileRead();
              return;
            }

            clearInterval(cbHandle);
            options.cb(100);

            if(dataTotal !== received)
            {
              return reject(new Error(`Progress Fetch did not receive total length: ${received} out of ${dataTotal}.`));
            }

            // Unify chunks:
            const data = new Uint8Array(received);
            let pos = 0;

            // Order the chunks by their respective position
            for(const chunk of chunks)
            {
              data.set(chunk, pos);
              pos += chunk.length;
            }

            // success, return the ArrayBuffer:
            resolve(data.buffer);
          }).catch(e => {
            clearInterval(cbHandle);
            reject(e);
          });
        };

        whileRead();
      } // status 200
      else
      {
        return reject(new Error(`Progress Fetch responded with !200: ${status} ${url}`));
      }
    }).catch(e => { reject(e); }); // fetch promise
  }); // promise
} // progressiveFetch

/**/// ########################################################################
/**
 * What needs to be acomplished:
 * Determine if the resource can be fetched from local physical cache. If
 * it can, then mark that in iDB, and keep loading it that way, no need to
 * add the resource to iDB.
 * If resource cannot be fetch from local physical cache, fetch it from
 * server, cache it in iDB.
 * @param {string} url
 * @param {object} options
 * @returns arraybuffer resource data
 */
async function syncCachedProgressiveFetch(url, options)
{
  options ??= { cb: () => {} };
  options.cache ??= 'default';

  if(options.cache === 'delete')
  {
    await IndexedDB.delete('res', url);
  }

  let row = await IndexedDB.get('res', url);

  if(row === undefined)
  {
    // There isnt an entry yet, create it:
    row = { url, hurl: url, cachestate: 'uncached', cache: null };
    await IndexedDB.add('res', row);
  }
  else
  {
    if(row.cachestate === 'cached_idb')
    {
      // console.log(`iDBCacheFetch: ${url}`);
      Browser.debug(20 * 1000, `iDB Cache: ${url}`);
      options.cb(100);
      return row.cache;
    }
  }

  options.cache = 'only-if-cached';
  let res = await progressiveFetch(url, options).catch(e => {});

  if(res !== undefined)
  { // resource is available locally cached:
    // console.log(`LocalCacheFetch: ${url}`);
    Browser.debug(20000, `LocalCacheFetch: ${url}`);
    return res;
  }

  options.cache = 'default';
  res = await progressiveFetch(url, options).catch(e => {});

  if(res !== undefined)
  {
    if(row.cachestate === 'fetched')
    {
      // we have already fetched this resource from the server
      // in the past, we are just going to locally cache it:
      row = { url, hurl: url, cachestate: 'cached_idb', cache: res };
      await IndexedDB.put('res', row);
    }
    else
    {
      // store in the DB that we already fetched from the server
      // this resource should now be local-cache accessible:
      row = { url, hurl: url, cachestate: 'fetched', cache: null };
      await IndexedDB.put('res', row);
    }
    return res;
  }

  return null;
}

/**/// ########################################################################
async function syncNonCachedProgressiveFetch(url, options)
{
  options ??= { cb: () => {} };
  options.cache ??= 'default';
  let res;

  // <section=cache.idb>
  options.cache = 'only-if-cached';
  res = await progressiveFetch(url, options).catch(e => {});

  if(res !== undefined)
  { // resource is available localy cached:
    Browser.debug(20000, `LocalCacheFetch: ${url}`);
    return res;
  }
  // </section=cache.idb>

  options.cache = 'default';
  res = await progressiveFetch(url, options).catch(e => {});

  return res;
}

/**/// ########################################################################
module.exports.progressiveFetch = syncNonCachedProgressiveFetch;
// <section=cache.idb>
if(IndexedDB !== null)
{
  module.exports.progressiveFetch = syncCachedProgressiveFetch;
}
// </section=cache.idb>
