/* // #########################################################################
: m.webasm.js
: 29.July.2022
: Load webASM code and return a callable proxy.
:
/**/// ########################################################################
/** @module webASM */

/**/// ########################################################################

/**
 * Parse an webasm arraybuffer into a callable proxy
 */
async function parseWebAsmArrayBuffer(abuf)
{
  const mod = await WebAssembly.compile(abuf);
  const instance = new WebAssembly.Instance(mod);

  const dictionary = [];
  const exclude = ['memory', '__indirect_function_table', '_initialize', '__errno_location', 'stackSave', 'stackRestore', 'stackAlloc'];

  for (const [key, func] of Object.entries(instance.exports))
  {
    if(exclude.includes(key)) continue;
    dictionary[key] = func;
  }

  // create mod proxy:
  const retmod = new Proxy({}, {
    get: (target, name, receiver) => {
      if (name === 'then') return null;
      if(typeof dictionary[name] === 'undefined')
      {
        throw new Error(`WASM Proxy - function [${name}] not parsed from wasm file`);
      }

      return function() { return dictionary[name](...arguments); };
    }
  }); // proxy

  return retmod;
}

/**/// ########################################################################
// TODO: allow this function to accept both a string (filename) and bytearray (wasm code)
/**
 * Fetches a wasm type file, and returns a proxy that makes it easier to call
 * @param {string} file
 * @returns proxy
 */
async function fetchCompileWasm(file)
{
  return new Promise((resolve, reject) => {
    if(file instanceof ArrayBuffer)
    {
      return resolve(parseWebAsmArrayBuffer(file));
    }

    throw new Error('WASM - function fetchCompileWasm should be called with ArrayBuffers only.');

    // fetch(`https://192.168.1.199:9901/${file}.wasm`)
    //   .then(bytes => bytes.arrayBuffer())
    //   .then(mod => {
    //     return resolve(parseWebAsmArrayBuffer(mod));
    //   }).catch(err => { reject(err); });
  }); // promise
}

/**/// ########################################################################
module.exports.fetchCompileWasm = fetchCompileWasm;
/**/// ########################################################################
