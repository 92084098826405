/* // #########################################################################
: m.render.base.js
: 07.March.2022
: Base SubClass the renderers will inherit from
:
/**/// ########################################################################
/** @module renderBase */

import { cRenderCamera } from './res_common/m.res.camera.js'

/**/// ########################################################################
import { mat4 } from '../../external/glmatrix/gl-matrix-min.js'

/**/// ########################################################################
/**
 * Class cRenderBase, base class for renderers.
 */
class cRenderBase
{
  constructor()
  {
    this.id = 'base';
    this.canvasEl = null;

    this.projection = {
      updated: false,
      matrix: mat4.create(),
      fov: 45.0,
      zNear: 0.1,
      zFar: 100.0
    };

    // Create the default Camera:
    this.Cameras = {};
    this.Cameras.default = new cRenderCamera();
    this.camera = this.Cameras.default;

    this.light = { x: 10.5, y: 3.0, z: 4.0 };
  }

  init()
  {
  }

  clear()
  {
  }

  render()
  {
  }
}

/**/// ########################################################################
module.exports.cRenderBase = cRenderBase;
/**/// ########################################################################
