/* // #########################################################################
: m.analytics.js
: 08.April.2022
:
: Responsability:
: * To handle analytics-related requests and events.
:
/**/// ########################################################################
/** @module analytics */

import { uuidv4 } from './m.utils.js'
// <section=gameanalytics>
const gameanalytics = require('gameanalytics');
// </section=gameanalytics>

/**/// ########################################################################
class cAnalytics
{
  constructor()
  {
    this.beaconCount = 0;
    this.beaconUID = uuidv4();
  }

  /**
   * generic event handler
   * this should call other specific analytics event handlers
   */
  event(action, more)
  {
    // <section=analytics>
    if(more === undefined) more = {};
    if(action === undefined)
    {
      throw new Error('[Engine][Analytics][Event] Action cannot be undefined.');
    }

    this.gaEvent(action, more);
    this.ping({ action, more });

    // <section=gameanalytics>
    this.gameAnalytics(action, more);
    // </section=gameanalytics>
  }

  /**
   * Google Analytics specific code for event sending
   */
  gaEvent(action, more)
  {
    if(window.gtag)
    {
      window.gtag('event', action, more);
    }
  }

  /**
   * GameAnalytics
   * @param {*} action
   * @param {*} more
   */
  gameAnalytics(action, more)
  {
    // <section=gameanalytics>
    more ??= 1;
    gameanalytics.GameAnalytics.addDesignEvent(action, more);
    // </section=gameanalytics>
  }

  /**
  * pings the SixPebbles analytics beacon
  */
  ping(action, more)
  {
    if(!navigator.sendBeacon) { return; };

    const msg = { action, more };
    const packet = { USC: this.beaconCount, USID: this.beaconUID, DATA: msg };
    this.beaconCount++;
    navigator.sendBeacon('https://sixpebbles.com:55111', JSON.stringify(packet));
  }
}

/**/// ########################################################################
export const Analytics = new cAnalytics();
