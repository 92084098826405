/* // #########################################################################
: m.engine.js
: 07.March.2022
: Agregates calls and rendering concepts.
:
/**/// ########################################################################
/** @module engine */

import { Utils, cTimer } from './m.utils.js';
import { Events } from './m.events.js';
import { Registry } from './m.registry.js';
import { Storage } from './m.storage.js';
import { Cookie } from './m.cookie.js';
import { Services } from './m.services.js';
import { Chains } from './m.chains.js';
import { Browser } from './m.browser.js';
import { Analytics } from './m.analytics.js';
import { Resources } from './m.resource.js';
import { Inputs } from './m.inputs.js';
import { User } from './m.user.js';

import { progressiveFetch } from './m.progfetch.js'

// Renderers:
import { cRenderWebGPU } from './m.render.webgpu.js';
import { cRenderWebGL2 } from './m.render.webgl2.js';
import { cRenderWebGL } from './m.render.webgl.js';

// Common Resources:
import { rWasm } from './res_common/m.res.wasm.js';
import { rPak } from './res_common/m.res.pak.js';

/**/// ########################################################################
/**
 * Engine base class
 */
class cEngineBase
{
  constructor()
  {
    // Register the engine
    if(window) window.joker = this;

    this.Events = Events;
    this.Registry = Registry;
    this.Storage = Storage;
    this.Cookie = Cookie;
    this.Services = Services;
    this.Utils = Utils;
    this.Chains = Chains;
    this.Analytics = Analytics;
    this.Browser = Browser;
    this.Resources = Resources;
    this.Inputs = Inputs;
    this.User = User;

    this.version = ['0', '2', '0'];

    this.Registry.version = {};
    this.Registry.version.string = '0.2.0';
    this.Registry.version.major = Number('0');
    this.Registry.version.minor = Number('2');
    this.Registry.version.revision = Number('0');

    this.render = null;
    this.Utils.printVersion(this.version);
    this.Browser.debug(5000, `Joker3D Engine v${this.Registry.version.string}`);

    this.Analytics.ping('id', {
      userID: this.User.id,
      version0: this.Registry.version.major,
      version1: this.Registry.version.minor,
      version2: this.Registry.version.revision,
      appver: 'prod'
    });

    this.Analytics.ping('caps', {
      iDB: this.Registry.support.indexedDB,
      localStorage: this.Registry.support.localStorage,
      webWorkers: this.Registry.support.webWorkers,
      webAsm: this.Registry.support.webASM
    });
  } // ctor

  /**
   * Initializes the events portion of the engine
   */
  init_events()
  {
    const _this = this;
    this.Events.sub('window_focus', 'main_window_focus', () => {
      if (_this.Registry.hasFocus) {
        _this.Browser.setPageTitle('Zeta (12Sep) ⬤');
      } else {
        _this.Browser.setPageTitle('Zeta (12Sep) ◯');
      }
    });

    this.Events.sub('window_close', 'engine_window_close', () => {
    });

    this.Events.sub('error', 'main_error', (error) => {
      console.log('error details:');
      console.log(error);
      const msg = `Error: <br>Message: ${error.message} `;
      this.Browser.debug('error', `⚠️: ${msg}`);
    });

    this.Events.sub('unhandled_rejection', 'main_error', (error) => {
      console.log('error details:');
      console.log(error);
      const msg = `Unhandled Rejection: ${error.event.reason}`;
      this.Browser.debug('errorur', `⚠️: ${msg}`);
    });

    // TODO: do something actually usefull with this:
    this.Events.sub('window_resize', 'engine_window_resize', (p) => {
      // let orientation = !window.screen.orientation.angle ? 'portrait' : 'landscape';
    });

    // TODO: what is this for?
    // this.Events.sub('engine_online', 'main_online', () => {
    //   //console.log(`Online: ${_this.Registry.online}`, 0);
    // });

    // TODO: remove this and instead use the environment module
    if (process.env.NODE_ENV !== 'production')
    {
      this.Events.sub('resource_change', 'main_resource_change', (o) => {
        const url = `local://${o.path}`;
        console.log(`event: resource change: ${url}`);
        Resources.triggerResReload(url);
      });
    }

    // Create a "playing event" that triggers every 5mins
  }

  /**
   * Initializes the Renderers for the engine
   */
  init_renderers()
  {
    const webGPU = false;
    const webGL2 = true;
    const webGL1 = true;

    // Detect an available renderer:
    this.Registry.hasRenderer = false;
    this.Registry.rendererType = null;
    this.Registry.renderer = null;
    this.Registry.resourcePathPrefix ??= {};

    if(('gpu' in navigator) && webGPU) // ## WebGPU:
    {
      Registry.hasRenderer = true;
      Registry.rendererType = 'webgpu';
      this.Registry.resourcePathPrefix.render = 'gpu';
      this.render = new cRenderWebGPU(this.Registry.dom.canvas);
    }

    if((!this.Registry.hasRenderer) && webGL2)// ## WebGL2:
    {
      const gl = this.Registry.dom.canvas.getContext('webgl2');
      if (gl !== null)
      {
        this.Registry.hasRenderer = true;
        this.Registry.rendererType = 'webgl2';
        this.Registry.resourcePathPrefix.render = 'gl2';
        this.render = new cRenderWebGL2(this.Registry.dom.canvas);
      }
    }

    if((!this.Registry.hasRenderer) && webGL1) // ## WebGL:
    {
      const gl = this.Registry.dom.canvas.getContext('webgl');
      if (gl !== null)
      {
        this.Registry.hasRenderer = true;
        this.Registry.rendererType = 'webgl';
        this.Registry.resourcePathPrefix.render = 'gl1';
        this.render = new cRenderWebGL(this.Registry.dom.canvas);
      }
    }

    if(!this.Registry.hasRenderer)
    {
      this.Utils.warning('No renderer found.');
      return;
    }

    this.Analytics.event('renderer', { type: this.Registry.rendererType });
    this.Utils.info(`Renderer: ${this.Registry.rendererType}`);
    this.Browser.debug(5000, `Renderer: ${this.Registry.rendererType}`);
    this.render.init();
  }

  /**
   * Initializes Resource Containers:
   */
  init_resource_containers()
  {
    // 

    // <section=prod>
    Resources.containers.register('local', (link) => {
      return new Promise(function(resolve, reject) {
        const url = `/assets/${link}`;

        progressiveFetch(url)
          .then(data => { resolve(data); })
          .catch(e => reject(e));
      });
    });

    Resources.containers.register('proxy', (link) => {
      return new Promise(function(resolve, reject) {
        const url = `/assets/${link}`;
        resolve(url);
      });
    });
    // </section=prod>
  }

  /**
   * Init the Resource Handlers
   */
  init_resource_handlers()
  {
    Resources.handlers.register('pak', (options) => { return new rPak(options); });
    Resources.handlers.register('wasm', (options) => { return new rWasm(options); });
  }

  /**
  * Initializes the engine, detects and selects a render sub-engine.
  * @returns void
  */
  init()
  {
    this.init_resource_containers();
    this.init_resource_handlers();
    this.init_events();
    this.init_renderers();
  }

  /**
   * The engine gameLoop
   */
  gameLoop()
  {
    const _this = this;
    this.rendertimer.end();
    this.frameElapsed = Math.round(this.rendertimer.elapsed);
    this.render_frame();
    this.Events.trigger('draw_frame');
    window.requestAnimationFrame(() => { _this.gameLoop(); });
  }

  /**
   * After initialization done, launch the game engine
   */
  async launch()
  {
    this.Analytics.event('engine', { type: 'resources', subtype: 'preload' });

    const timer = new cTimer();
    timer.start();
    await this.loadResources();
    timer.end();
    const elapsedms = Math.round(timer.elapsed);

    // Record load times:
    this.Analytics.event('engine', { type: 'resources', subtype: 'loaded' });
    this.Analytics.event('engine', { type: 'resources', subtype: 'loadtime', elapsedms });

    this.rendertimer = new cTimer();
    this.rendertimer.start();
    this.gameLoop();
  }

  /**
   *
   */
  loadResources()
  {

  }

  /**
  * Shutdown the engine
  */
  shutdown ()
  {

  }

  /**
   * Animate the scene objects
   */
  animate()
  {
  }

  /**
  * Renders a single frame of the engine
  */
  render_frame()
  {
  }
} // cEngineBase

/**/// ########################################################################
module.exports.cEngineBase = cEngineBase;
/**/// ########################################################################
