/* // #########################################################################
: m.codetailor.js
: 29.Aug.2022
: Allows us to easily create code sequences and execute them.
:
/**/// ########################################################################

/**/// ########################################################################
class cCodeTailor
{
  constructor()
  {
    this.code = '';
    this.func = () => {};
  }

  /**
   * Return a compiled function
   */
  getEval()
  {
    // eslint-disable-next-line no-eval
    this.func = eval(this.code);
    return this.func;
  }

  /**
   * executes the code
   */
  exec()
  {
    this.func(...arguments);
  }

  add(lines)
  {
    this.code += lines + '\n';
  }

  replace(label, val)
  {
    this.code = this.code.replaceAll(label, val);
  }
}

/**/// ########################################################################
module.exports.cCodeTailor = cCodeTailor;
