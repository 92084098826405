/* // #########################################################################
: m.res.object.js
: 18.May.2022
: Resource class for Object under WebGL2
:
/**/// ########################################################################
/** @module res.vertexshader.webgl2 */

import { Resources, cResourceBase, ResState } from '../m.resource.js'

/**/// ######################################################################## obj
class rObject extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.rawToString();
    this.gl = options.gl;
    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  setupBase()
  {
    this.meta = {};
    this.active = false;

    this.meta.isTextured = false;
    this.meta.firstPass = true;

    // Picking:
    this.meta.pickable = false;
    this.meta.pickColor = [0, 0, 0];

    // Position, Rotation:
    this.meta.pos = { x: 0, y: 0, z: 0 };
    this.meta.rot = { x: 0, y: 0, z: 0 };
  }

  // ############################################
  // compute and return dependencies
  preload()
  {
    this.setupBase();
    const deps = [];

    const obj = JSON.parse(this.rawData);
    this.meta.obj = obj;

    // Import details:
    for (const [key, value] of Object.entries(obj)) {
      this.meta[key] = value;
    }

    deps.push(this.meta.geometry);
    deps.push(this.meta.shaderProgram);

    this.meta.isTextured = false;

    if('texture' in this.meta)
    {
      if(this.meta.texture instanceof Array)
      {
        this.meta.texture.forEach(item => { deps.push(item); });
        this.meta.isTextured = true;
      }

      if(typeof this.meta.texture === 'string')
      {
        deps.push(this.meta.texture);
        this.meta.texture = [this.meta.texture];
        this.meta.isTextured = true;
      }
    }

    return deps;
  }

  // ############################################
  waitForDeps()
  {
    for(const [, dep] of Object.entries(this.deps))
    {
      if(dep.state === ResState.FAILED)
      {
        throw Error(`Object.build failed on dependency [${dep.url}]`);
      }

      if(dep.state !== ResState.VALIDATED)
      {
        const _this = this;
        setTimeout(() => { _this.build(); }, 50);
        return false;
      }
    }

    return true;
  }

  // ############################################
  /**
   * Links Resource Dependencies and Maps by Url
   */
  linkMap()
  {
    for(const [, dep] of Object.entries(this.deps))
    {
      Resources.link(this.id, dep.id);
    }

    // dependency mapper:
    this._map = {};
    for(const [, dep] of Object.entries(this.deps))
    {
      this._map[Resources.getNameFromUrl(dep.url)] = dep;
    }
  }

  // ############################################
  map(url)
  {
    url = Resources.getNameFromUrl(url);
    return this._map[url];
  }

  // ############################################
  build()
  {
    if(!this.waitForDeps()) return;

    this.linkMap();

    this.meta.vars = {};

    // Shader Program:
    this.meta.shaderProgram = this.map(this.meta.obj.shaderProgram);

    // Geometry:
    this.meta.geometry = this.map(this.meta.obj.geometry);
    this.meta.geometryAtHandle = this.gl.getAttribLocation(this.meta.shaderProgram.meta.program, this.meta.obj.geometry.attribute);

    // Texture:
    for(let i = 0; i < 4; i++)
    {
      if(!this.meta.obj.texture[i]) continue;
      this.meta[`texture${i}`] = this.map(this.meta.obj.texture[i]);
    }

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged(dep)
  {
    if(dep && dep.type === 'sp')
    {
      this.meta.shaderProgram = dep;
    }

    this.state = ResState.VALIDATED;
    this.active = true;

    this.changed();
  }

  /**
   * clones this resource and returns a new one
   */
  clone()
  {

  }
}

/**/// ########################################################################
module.exports.rObject = rObject;
/**/// ########################################################################
