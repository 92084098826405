/* // #########################################################################
: m.render.webgl.js
: 07.March.2022
: SubClass that manages draw calls using webGL
:
/**/// ########################################################################
/** @module renderWebGL */
import { Utils } from './m.utils.js';
import { cRenderBase } from './m.render.base.js';
import { Events } from './m.events.js';
import { Browser } from './m.browser.js';
import { Resources, ResState } from './m.resource.js'
import { cCodeTailor } from './m.codetailor.js'
import { mat4, mat3, vec3 } from '../../external/glmatrix/gl-matrix-min.js'

// Resource Classes (WebGL2):
import { rGL2VertexShader } from './res_wgl2/m.res.vertexshader.js'
import { rGL2FragmentShader } from './res_wgl2/m.res.fragmentshader.js'
import { rGL2ShaderProgram } from './res_wgl2/m.res.shaderprogram.js'
import { rGL1BMesh } from './res_wgl1/m.res.bmesh.js'
import { rObject } from './res_wgl2/m.res.object.js'
import { rGL2Image } from './res_wgl2/m.res.image.js'

/**/// ########################################################################
/**
 * Render Class specialized for WebGL v1
 * @extends cRenderBase
 */
class cRenderWebGL extends cRenderBase
{
  constructor(canvasEl)
  {
    super();
    this.id = 'webgl';
    this.canvasEl = canvasEl;
    this.gl = canvasEl.getContext('webgl');

    this.extensions = [];
    if('getSupportedExtensions' in this.gl)
    {
      this.extensions = this.gl.getSupportedExtensions();
    }

    const slver = this.gl.getParameter(this.gl.SHADING_LANGUAGE_VERSION);
    const ver = this.gl.getParameter(this.gl.VERSION);
    Utils.info(slver);
    Utils.info(ver);

    Browser.debug(5000, `${slver}`);
    Browser.debug(5000, `${ver}`);

    // const maxVertexShaderTextureUnits = this.gl.getParameter(this.gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS);
    // const maxFragmentShaderTextureUnits = this.gl.getParameter(this.gl.MAX_TEXTURE_IMAGE_UNITS);

    // Browser.debug(5000, `maxVertexShaderTextureUnits: ${maxVertexShaderTextureUnits}`);
    // Browser.debug(5000, `maxFragmentShaderTextureUnits: ${maxFragmentShaderTextureUnits}`);

    // Register Loaders
    this.register_loaders();

    // Set the draw bucket:
    this.drawBucket = [];

    Events.sub('resource_register', 'rend_webgl1', (param) => {
      if(param.res.type !== 'rend') return;
      this.drawBucket.push(param.res);
    });

    // Set default camera position & target:
    this.camera.setPos(0.0, 0.0, 1.0);
    this.camera.setTarget(0.0, 0.0, 0.0);
    this.camera.updateMatrix();

    this.shaderVars = {};
    this.firstPass = true;

    // Calculate the projection matrix:
    this.calcProjectionMatrix();
  }

  /** #########################################################################
   * Simply register the file type loaders we'll use with GL2
   */
  register_loaders()
  {
    const _this = this;

    Resources.handlers.register('vs', (options) => { return new rGL2VertexShader({ gl: _this.gl, ...options }); });
    Resources.handlers.register('fs', (options) => { return new rGL2FragmentShader({ gl: _this.gl, ...options }); });
    Resources.handlers.register('sp', (options) => { return new rGL2ShaderProgram({ gl: _this.gl, ...options }); });
    Resources.handlers.register('bmesh', (options) => { return new rGL1BMesh({ gl: _this.gl, ...options }); });
    Resources.handlers.register('obj', (options) => { return new rObject({ gl: _this.gl, ...options }); });
    Resources.handlers.register('jpg', (options) => { return new rGL2Image({ imgtype: 'jpg', gl: _this.gl, ...options }); });
    Resources.handlers.register('jpeg', (options) => { return new rGL2Image({ imgtype: 'jpeg', gl: _this.gl, ...options }); });
    Resources.handlers.register('png', (options) => { return new rGL2Image({ imgtype: 'png', gl: _this.gl, ...options }); });
    Resources.handlers.register('webp', (options) => { return new rGL2Image({ imgtype: 'webp', gl: _this.gl, ...options }); });
  }

  /** #########################################################################
   * returns true is specified extension is present
   * @param {string} ext
   */
  hasExtension(ext)
  {
    if(ext in this.extensions) return true;
    return false;
  }

  /** #########################################################################
   *
   */
  ev_window_resize()
  {
    this.canvasEl.width = this.width = this.canvasEl.offsetWidth;
    this.canvasEl.height = this.height = this.canvasEl.offsetHeight;
    this.gl.viewport(0, 0, this.width, this.height);

    this.calcProjectionMatrix();
  }

  /** #########################################################################
   * Calculates the Projection Matrix
   */
  calcProjectionMatrix()
  {
    // Projection:
    const fieldOfView = this.projection.fov * Math.PI / 180; // in radians
    const aspect = this.gl.canvas.clientWidth / this.gl.canvas.clientHeight;

    mat4.perspective(this.projection.matrix,
      fieldOfView,
      aspect,
      this.projection.zNear,
      this.projection.zFar);
  }

  /** #########################################################################
   * Performs initialization
   */
  init()
  {
    const _this = this;
    Events.sub('window_resize', 'render_window_resize', (p) => {
      _this.ev_window_resize();
    });
    this.ev_window_resize();
    this.clear();
  }

  /** #########################################################################
   * Clears the canvas
   */
  clear()
  {
    if(this.firstPass)
    {
      this.gl.enable(this.gl.DEPTH_TEST); // Enable depth testing
      this.gl.depthFunc(this.gl.LEQUAL); // Near things obscure far things

      this.gl.enable(this.gl.BLEND);
      this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);

      this.gl.cullFace(this.gl.BACK);

      this.gl.clearColor(0.0, 0.0, 0.0, 1.0); // Clear to black, fully opaque
      this.gl.clearDepth(1.0); // Clear everything

      this.firstPass = false;
      return;
    }

    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
  }

  /** #########################################################################
   * Takes care of object initialization for rendering
   * @param {*} res
   */
  renderObjectFirstPass(res)
  {
    res.meta.firstPass = false;
    const master = res;
    res = res.obj;

    const STRIDE = res.meta.geometry.meta.stride;
    let entry;
    let buffer;

    // Create tailored code:
    const code = new cCodeTailor();
    code.add('(res, gl, globalVars) => { let master = res; res = res.obj; ');

    // Cache for fast access:
    res.cache = {};

    // Activate the object's shader program
    code.add('gl.useProgram(res.meta.shaderProgram.meta.program);');

    // Use the buffer:
    code.add('gl.bindBuffer(gl.ARRAY_BUFFER, res.meta.geometry.meta.buffer);');

    // ## Geometry Buffer:
    entry = res.meta.shaderProgram.shaderBabel.getvar('aVertexPosition');
    buffer = res.meta.geometry.meta.buffers.geometry;
    if(buffer && entry)
    {
      this.gl.vertexAttribPointer(entry.handle, buffer.numEl, buffer.type, false, STRIDE, buffer.offset);
      this.gl.enableVertexAttribArray(entry.handle);
      res.cache.handleGeometry = entry.handle;
      code.add(`gl.vertexAttribPointer(res.cache.handleGeometry, ${buffer.numEl}, gl.FLOAT, false, ${STRIDE}, ${buffer.offset});`);
    }

    // ## Texture Coordinates Buffer:
    entry = res.meta.shaderProgram.shaderBabel.getvar('aTextureCoord');
    buffer = res.meta.geometry.meta.buffers.texture;
    if(buffer && entry)
    {
      this.gl.vertexAttribPointer(entry.handle, buffer.numEl, buffer.type, false, STRIDE, buffer.offset);
      this.gl.enableVertexAttribArray(entry.handle);
      res.cache.handleTexture = entry.handle;
      code.add(`gl.vertexAttribPointer(res.cache.handleTexture, ${buffer.numEl}, gl.FLOAT, false, ${STRIDE}, ${buffer.offset});`);
    }

    // ## Normals Buffer:
    entry = res.meta.shaderProgram.shaderBabel.getvar('aVertexNormal');
    buffer = res.meta.geometry.meta.buffers.normals;
    if(res.meta.geometry.meta.buffers.normals && entry)
    {
      this.gl.vertexAttribPointer(entry.handle, buffer.numEl, buffer.type, false, STRIDE, buffer.offset);
      this.gl.enableVertexAttribArray(entry.handle);
      res.cache.handleNormals = entry.handle;
      code.add(`gl.vertexAttribPointer(res.cache.handleNormals, ${buffer.numEl}, gl.FLOAT, false, ${STRIDE}, ${buffer.offset});`);
    }

    for(let i = 0; i < 4; i++)
    {
      if((res.meta[`texture${i}`]) && (res.meta.shaderProgram.shaderBabel.getvar(`uSampler${i}`)))
      {
        code.add(`
          gl.activeTexture(gl.TEXTURE${i});
          gl.bindTexture(gl.TEXTURE_2D, res.meta.texture${i}.meta.texture);
          gl.uniform1i(res.meta.shaderProgram.shaderBabel.getvar('uSampler${i}').handle, ${i});`);
      }
    }

    res.meta.shaderProgram.shaderBabel.forEach((entry) => {
      if(entry.label in master.meta.vars)
      {
        res.cache[`shader_var_${entry.label}`] = entry.handle;
        const method = res.meta.shaderProgram.shaderBabel.getMethodFromType(entry.type, entry.vartype);

        code.add(`gl.${method};`);
        code.replace('{#a#}', `res.cache.shader_var_${entry.label}`);
        code.replace('{#b#}', `master.meta.vars['${entry.label}']`);
      }

      if(entry.label in this.shaderVars)
      {
        res.cache[`shader_var_${entry.label}`] = entry.handle;
        const method = res.meta.shaderProgram.shaderBabel.getMethodFromType(entry.type, entry.vartype);

        code.add(`gl.${method};`);
        code.replace('{#a#}', `res.cache.shader_var_${entry.label}`);
        code.replace('{#b#}', `globalVars['${entry.label}']`);
      }
    });

    // # Geometry render type:
    if(res.meta.geometry.meta.isIndexed)
    {
      code.add(`
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, res.meta.geometry.meta.indexBuffer);
      gl.drawElements(gl.TRIANGLES, ${res.meta.geometry.meta.elements}, gl.UNSIGNED_SHORT, 0);`);
    }

    code.add('}');

    // console.log(code.code);
    master.meta.code = code.getEval();
  }

  /** #########################################################################
   * Render a single specific Object resource
   * @param {*} res
   */
  renderObject(res)
  {
    res.mm = mat4.create();

    // Translation:
    mat4.translate(res.mm, res.mm,
      [res.meta.pos.x, res.meta.pos.y, res.meta.pos.z]);

    // Rotation:
    mat4.rotateX(res.mm, res.mm, res.meta.rot.x);
    mat4.rotateY(res.mm, res.mm, res.meta.rot.y);
    mat4.rotateZ(res.mm, res.mm, res.meta.rot.z);

    // Scale:
    const scale = 1.0;
    const v = vec3.create();
    vec3.set(v, scale, scale, scale);
    mat4.scale(res.mm, res.mm, v);

    // Set model specific shader vars:
    res.meta.vars['uModelMatrix'] = res.mm;
    res.meta.vars['uNormalMatrix'] = mat3.normalFromMat4(mat3.create(), res.mm);

    // Dynamic code:
    if(res.meta.firstPass)
    {
      return this.renderObjectFirstPass(res);
    }

    res.meta.code(res, this.gl, this.shaderVars);
  }

  /** #########################################################################
   * Calculates and updates global shader parameters:
   */
  calcGlobalShaderVars()
  {
    // Update:
    this.camera.updateMatrix();

    // Set render shader parameters:
    this.shaderVars['uProjectionMatrix'] = this.projection.matrix
    this.shaderVars['uViewMatrix'] = this.camera.matrix;
    this.shaderVars['uViewPos'] = [this.camera.pos.x, this.camera.pos.y, this.camera.pos.z];
    this.shaderVars['uLightPoint'] = [this.light.x, this.light.y, this.light.z];
  }

  /** #########################################################################
   * Render the active resource objects
   */
  render()
  {
    // if (!Registry.hasFocus) return;
    this.clear();
    this.calcGlobalShaderVars();

    // Render:
    this.drawBucket.forEach(res => {
      if(res.state !== ResState.VALIDATED) return;
      if(res.active !== true) return;
      if(res.obj.state !== ResState.VALIDATED) return;
      if(res.obj.active !== true) return;

      this.renderObject(res);
    });
  }
}

/**/// ########################################################################
module.exports.cRenderWebGL = cRenderWebGL;
/**/// ########################################################################
