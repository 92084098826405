/**/// ########################################################################
// by Hugo Ferreira 02 Aug 2022
// handles PAK File reading
/**/// ########################################################################

/**/// ########################################################################
class cBinRead
{
  constructor(buf)
  {
    this.offset = 0;
    this.buf = new DataView(buf);
  }

  rF()
  {
    const n = this.buf.getFloat32(this.offset, true);
    this.offset += 4;
    return n;
  }

  rS()
  {
    let str = '';

    let code = this.rUI8();
    if(code === 0) return '';

    while(code !== 0)
    {
      str += String.fromCharCode(code);
      code = this.rUI8();
    }

    return str;
  }

  rUI8()
  {
    const n = this.buf.getInt8(this.offset, true);
    this.offset += 1;
    return n;
  }

  rUI16()
  {
    const n = this.buf.getUint16(this.offset, true);
    this.offset += 2;
    return n;
  }

  rUI32()
  {
    const n = this.buf.getUint32(this.offset, true);
    this.offset += 4;
    return n;
  }
}

/**/// ########################################################################
class cPakRead
{
  constructor(buffer)
  {
    this.buf = buffer;
    this.bin = new cBinRead(buffer);
    this.version = [0, 0, 0];
    this.list = [];
    this.numFiles = 0;
    this.valid = this.validateMagicBytes();
  }

  /**
   * Validate the magic bytes on the PAK header.
   * @returns true if valid PAK
   */
  validateMagicBytes()
  {
    if(this.bin.rUI8() !== 71) return false;
    if(this.bin.rUI8() !== 79) return false;
    if(this.bin.rUI8() !== 72) return false;
    if(this.bin.rUI8() !== 85) return false;

    if(this.bin.rUI8() !== 80) return false;
    if(this.bin.rUI8() !== 65) return false;
    if(this.bin.rUI8() !== 75) return false;

    return true;
  }

  /**
   * Parses the PAK header structure
   * @returns true if valid PAK, false otherwise.
   */
  parse()
  {
    if(!this.valid) return false;

    // Version
    this.version[0] = this.bin.rUI8();
    this.version[1] = this.bin.rUI8();
    this.version[2] = this.bin.rUI8();

    // Number of files present in the pack:
    this.numFiles = this.bin.rUI16();

    // Read each entry:
    let entry = {};
    this.list = [];

    for(let n = 0; n < this.numFiles; n++)
    {
      const start = this.bin.rUI32();
      const size = this.bin.rUI32();
      const label = this.bin.rS();
      const hash = this.bin.rS();

      entry = { label, start, size, hash };
      this.list.push(entry);
    }

    const relist = [];
    for(const [, value] of Object.entries(this.list))
    {
      const start = value.start + this.bin.offset;
      const entry = { label: value.label, start, end: start + value.size };
      relist.push(entry);
    }

    this.list = relist;

    this.valid = true;
    return true;
  }
} // cPakRead

/**/// ########################################################################
module.exports.cPakRead = cPakRead;
