/** ###########################################################################
 * @file Code execution begins here.
 * @author © Hugo Ferreira - 30.March.2022
 */
/**/// ########################################################################
// <section=logrocket>
import LogRocket from 'logrocket'
// </section=logrocket>

// <section=gameanalytics>
const gameanalytics = require('gameanalytics');
// </section=gameanalytics>

import { Engine } from './mods/m.engine.js'
import { Utils } from '../joker/client/m.utils.js'
// 

/**/// ########################################################################
/**
 * main function, engine logic begins here.
 */
function main()
{
  if(window.location.protocol !== 'https:')
  {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    return;
  }

  // <section=gameanalytics>
  gameanalytics.GameAnalytics.setEnabledInfoLog(true);
  gameanalytics.GameAnalytics.configureBuild(`zeta 0.2.0`);
  gameanalytics.GameAnalytics.initialize('d4b557058c38cabe3a98c7c44fa12dff', '3ed3be37ee8a74ee03c3245be3ad763445f0b386');
  // </section=gameanalytics>

  // <section=logrocket>
  LogRocket.init('xonh4d/joker3dlive');
  // </section=logrocket>

  Engine.init();
  Engine.launch();
  
  // 
}

/**/// ########################################################################
document.onreadystatechange = () => { if (document.readyState === 'complete') { main(); } };
/**/// ########################################################################
