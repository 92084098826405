/* // #########################################################################
: m.storage.js
: 30.March.2022
:
: Responsability:
:   Manages setting/getting of values in local storage.
:
/**/// ########################################################################
/** @module storage */

import { Registry } from './m.registry.js';

/**/// ########################################################################
class cStorage
{
  constructor()
  {
    Registry.storage = false;
    this.available = false;
    try {
      const str = '_____string';
      localStorage.setItem(str, str);
      localStorage.removeItem(str);
      this.available = true;
      Registry.storage = true;
    } catch (e) {
      this.available = false;
    }
  }

  /**
     * Sets a local storage entry.
     * @param {string} key
     * @param {string|number} value
     */
  set(key, value)
  {
    if (!this.available) return;
    window.localStorage.setItem(key, value);
  }

  /**
     * Returns a local storage entry
     * @param {string} key
     * @returns local storage entry
     */
  get(key)
  {
    if (!this.available) return {};
    return window.localStorage.getItem(key);
  }

  /**
     * Deletes a key entry in localStorage.
     * @param {string} key
     */
  remove(key)
  {
    if (!this.available) return;
    window.localStorage.removeItem(key);
  }

  /**
     * Zeroes out all local storage.
     */
  clear()
  {
    if (!this.available) return;
    window.localStorage.clear();
  }
}

/**/// ########################################################################
export const Storage = new cStorage();
/**/// ########################################################################
