/**/// ########################################################################
// PRODUCTION:
const env_production = {
  environment: 'production',
  debug: false,
  trigger_res_change: false,
  gameserver: {
    active: false,
    url: '',
    port: 0
  }  
};

/**/// ########################################################################
// DEVELOPMENT:
const env_development = {
  environment: 'development',
  debug: true,
  trigger_res_change: true,
  gameserver: {
    active: true,
    url: '192.168.1.199',
    port: 11223
  }
};

/**/// ########################################################################
// BASE CONFIG:
let config = {};

/**/// ########################################################################
if (process.env.NODE_ENV === 'production')
{
  config = {...env_production, ...config};
} else {
  config = {...env_development, ...config};
}

/**/// ########################################################################
module.exports = { config };
/**/// ########################################################################
