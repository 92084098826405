/**
 * @file Deals with browser detection and events set up.
 * @author Hugo Ferreira © 04.March.2022
 */
/**/// ########################################################################
/** @module browser */

import { Events } from './m.events.js';
import { Registry } from './m.registry.js';
import { IndexedDB } from './m.browser.indexedDB.js'
import { BrowSupport } from './m.browser.support.js'

/**/// ########################################################################
/**
 * class cBrowser - Deals with browser feature detection and events set up.
 */
class cBrowser
{
  constructor()
  {
    this.IndexedDB = IndexedDB;
    this.setupEventCapturing();
    this.createDOMElements();
    this.detectFeatureSupport();
  }

  /**
   * Captures events from the browser.
   */
  setupEventCapturing()
  {
    // window resize
    window.addEventListener('resize', function() {
      Events.trigger('window_resize', { height: window.innerHeight, width: window.innerWidth });
    });

    // window close
    window.addEventListener('beforeunload', function() {
      Events.trigger('window_close');
    });

    // Online?
    Registry.online = false;
    if (window.navigator.onLine) { Registry.online = window.navigator.onLine; }
    window.addEventListener('online', () => { Registry.online = true; Events.trigger('window_online', {}); });
    window.addEventListener('offline', () => { Registry.online = false; Events.trigger('window_online', {}); });
    Events.trigger('window_online', {});

    // Window Focus:
    window.addEventListener('blur', function() {
      Registry.hasFocus = document.hasFocus();
      Events.trigger('window_focus', {});
    });

    window.addEventListener('focus', function() {
      Registry.hasFocus = document.hasFocus();
      Events.trigger('window_focus', {});
    });

    Registry.hasFocus = document.hasFocus();
    Events.trigger('window_focus', {});

    // window device orientation:
    // if (window.DeviceOrientationEvent) {
    //     window.addEventListener('deviceorientation', function(event) {
    //         var alpha = event.alpha;
    //         var beta = event.beta;
    //         var gamma = event.gamma;
    //         // Do something
    //         Events.trigger('device_orientation', {alpha, beta, gamma});
    //       }, false);
    // }

    // Global Error:
    window.addEventListener('error', function(event) {
      const error = {};
      error.line = event.lineno;
      error.column = event.colno;
      error.type = event.type;
      error.message = event.message;
      error.file = event.filename;

      Events.trigger('error', error);
    })

    // Unhandled Rejection:
    window.addEventListener('unhandledrejection', event => {
      Events.trigger('unhandled_rejection', { event });
    });
  }

  /**
   * Sets the page title.
   * @param {string} title
   */
  setPageTitle(title)
  {
    document.title = title;
  }

  /**
   * Creates the page DOM elements for the engine to function properly.
   */
  createDOMElements()
  {
    const d0 = document.createElement('div');
    const d1 = document.createElement('div');
    const d2 = document.createElement('div');
    const d3 = document.createElement('div');
    const d4 = document.createElement('div');

    d0.classList.add('window_div');
    d1.classList.add('window_div');
    d2.classList.add('window_div');
    d3.classList.add('window_div');
    d4.classList.add('window_div');

    d0.id = 'div_layer_under';
    d1.id = 'div_layer_game';
    d2.id = 'div_layer_ui';
    d3.id = 'div_layer_over';
    d4.id = 'div_layer_debug';

    d0.style.zIndex = 100;
    d1.style.zIndex = 200;
    d2.style.zIndex = 300;
    d3.style.zIndex = 400;
    d4.style.zIndex = 500;

    // turn off pointer events for debug layer:
    d4.style.pointerEvents = 'none';

    document.body.appendChild(d0);
    document.body.appendChild(d1);
    document.body.appendChild(d2);
    document.body.appendChild(d3);
    document.body.appendChild(d4);

    Registry.dom = {};
    Registry.dom.layer_under = d0;
    Registry.dom.layer_game = d1;
    Registry.dom.layer_ui = d2;
    Registry.dom.layer_over = d3;
    Registry.dom.layer_debug = d4;

    const canvas = document.createElement('canvas');
    canvas.id = 'game_canvas';
    canvas.width = '100vw';
    canvas.height = '100vh';
    canvas.classList.add('game_canvas');

    Registry.dom.canvas = canvas;
    Registry.dom.layer_game.appendChild(canvas);
  }

  /**
   * Opens a DOM element into fullscreen.
   * @param {string} id
   */
  enterFullScreen(id)
  {
    const el = document.getElementById(id);
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    }
  }

  /**
   * Exits full screen mode.
   */
  exitFullScreen()
  {
    Document.exitFullscreen();
  }

  /**
   * Place a debug message on the topmost layer on a specific slot
   * @param {string} slot
   * @param {string} msg
   */
  debug(poly, msg)
  {
    const div = Registry.dom.layer_debug;
    let slot;
    let timer;
    if(typeof poly === 'string')
    {
      slot = poly;
    }

    if(typeof poly === 'number')
    {
      timer = poly;
    }

    if(timer)
    {
      const el = document.createElement('div');
      div.appendChild(el);
      el.innerHTML = msg;

      setTimeout(function() {
        el.remove();
      }, timer);

      return;
    }

    let el = document.getElementById(`debug_slot_${slot}`);
    if(!el)
    {
      el = document.createElement('div');
      el.id = `debug_slot_${slot}`;
      div.appendChild(el);
    }

    el.innerHTML = msg;
  }

  /**
   * Detect browser-specific feature support
   */
  detectFeatureSupport()
  {
    Registry.support ??= {};

    Registry.support.indexedDB = BrowSupport.supports_indexedDB();
    Registry.support.localStorage = BrowSupport.supports_localStorage();
    Registry.support.webWorkers = BrowSupport.supports_web_workers();
    Registry.support.webASM = BrowSupport.supports_webASM();

    // webp:
    Registry.support.webp_lossy = 'waiting';
    Registry.support.webp_lossless = 'waiting';
    Registry.support.webp_alpha = 'waiting';
    Registry.support.webp_animation = 'waiting';

    BrowSupport.supports_webp('lossy', (feature, result) => {
      Registry.support.webp_lossy = result;
    });
    BrowSupport.supports_webp('lossless', (feature, result) => {
      Registry.support.webp_lossless = result;
    });
    BrowSupport.supports_webp('alpha', (feature, result) => {
      Registry.support.webp_alpha = result;
    });
    BrowSupport.supports_webp('animation', (feature, result) => {
      Registry.support.webp_animation = result;
    });
  }
}

/**/// ########################################################################
export const Browser = new cBrowser();
/**/// ########################################################################
