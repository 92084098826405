/**
 * @file Deals with User data
 * @author Hugo Ferreira © 12.September.2022
 */
/**/// ########################################################################
/** @module user */

import { Cookie } from './m.cookie.js';
import { uuidv4 } from './m.utils.js';

/**
 * This class takes care of detecting browser support.
 */
class cUser
{
  constructor()
  {
    this.Cookie = Cookie;
    this.id = Cookie.get('user_id');
    if(this.id === '')
    {
      this.id = uuidv4();
      Cookie.set('user_id', this.id);
    }

    this.name = '';
    this.image = '';
  }
}

/**/// ########################################################################
export const User = new cUser();
/**/// ########################################################################
