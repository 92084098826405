/* // #########################################################################
: m.res.image.js
: 18.May.2022
: Resource class for Images under WebGL2
:
/**/// ########################################################################
/** @module res.images.webgl2 */

import { cResourceBase, ResState } from '../m.resource.js'

/**/// ######################################################################## mesh
class rGL2Image extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.gl = options.gl;
    this.init();
  }

  // ############################################
  build()
  {
    const _this = this;
    this.meta = {};

    this.meta.texture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.meta.texture);

    const level = 0;
    const internalFormat = this.gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = this.gl.RGBA;
    const srcType = this.gl.UNSIGNED_BYTE;
    const pixel = new Uint8Array([255, 0, 0, 255]);
    const ext = this.gl.getExtension('EXT_texture_filter_anisotropic');

    this.gl.texImage2D(this.gl.TEXTURE_2D, level, internalFormat,
      width, height, border, srcFormat, srcType, pixel);

    this.meta.image = new Image();
    this.meta.image.crossOrigin = 'anonymous';

    this.meta.image.onload = function() {
      _this.gl.pixelStorei(_this.gl.UNPACK_FLIP_Y_WEBGL, true);
      _this.gl.bindTexture(_this.gl.TEXTURE_2D, _this.meta.texture);

      if(ext)
      {
        const max = _this.gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
        _this.gl.texParameterf(_this.gl.TEXTURE_2D, ext.TEXTURE_MAX_ANISOTROPY_EXT, max);
      }

      _this.gl.texImage2D(_this.gl.TEXTURE_2D, level, internalFormat,
        srcFormat, srcType, _this.meta.image);

      _this.gl.generateMipmap(_this.gl.TEXTURE_2D);

      _this.state = ResState.VALIDATED;
      _this.active = true;
    }; // onload

    if(typeof this.rawData === 'string')
    {
      this.meta.image.src = this.rawData;
    }
    else
    {
      this.imgtype = `image/${this.options.imgtype}`;
      const blob = new Blob([this.rawData], { type: this.imgtype });
      this.meta.image.src = URL.createObjectURL(blob);
    }
  }

  // ############################################
  evSubChanged()
  {
    throw Error('evSubChanged request on image resource');
  }
}

/**/// ########################################################################
module.exports.rGL2Image = rGL2Image;
/**/// ########################################################################
