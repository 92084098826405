/* // #########################################################################
: m.res.bmesh.js
: 25.Aug.2022
: Resource class for Binary Mesh under WebGL1
:
/**/// ########################################################################
/** @module res.vertexshader.webgl1 */

import { cResourceBase, ResState } from '../m.resource.js'

/**/// ######################################################################## mesh
class rGL1BMesh extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.gl = options.gl;
    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  validateHeader(header)
  {
    let valid = true;

    // Magic Bytes:
    if(header[0] !== 71) valid = false;
    if(header[1] !== 79) valid = false;
    if(header[2] !== 72) valid = false;
    if(header[3] !== 85) valid = false;

    // Version:
    if(header[4] !== 0) valid = false;
    if(header[5] !== 0) valid = false;
    if(header[6] !== 1) valid = false;

    if(!valid)
    {
      throw new Error('Invalid BMESH Header signature.');
    }
  }

  /** ############################################
   * WebGL1 does not have srcOffset or length, so we use this
   * function as a conversion:
   * @param {*} target
   * @param {*} srcData
   * @param {*} usage
   * @param {*} srcOffset
   * @param {*} length
   */
  glBufferData(target, srcData, usage, srcOffset, length)
  {
    if(srcOffset !== undefined)
    {
      // slice the srcData:
      const newABuf = srcData.slice(srcOffset, srcOffset + length);
      this.gl.bufferData(target, newABuf, usage);
      return;
    }

    this.gl.bufferData(target, srcData, usage);
  }

  // ############################################
  // Indices
  importBufType0(start)
  {
    const a = this.rawData[start + 1];
    const b = this.rawData[start + 2];

    const numIndices = (b * 256) + a;
    this.meta.elements = numIndices;

    start += 3;
    const indexOffset = start;
    const indexLen = numIndices * 2;

    this.meta.isIndexed = true;
    this.meta.indexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.meta.indexBuffer);
    this.glBufferData(this.gl.ELEMENT_ARRAY_BUFFER, this.rawData, this.gl.STATIC_DRAW, indexOffset, indexLen);

    start += indexLen;

    return start;
  }

  // ############################################
  // Data: Position, Texture, Normals:
  importBufType1(start)
  {
    const a = this.rawData[start + 1];
    const b = this.rawData[start + 2];

    const numEntries = (b * 256) + a;
    start += 3;

    const dataOffset = start;
    const dataLen = numEntries * 4;

    this.meta.buffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.meta.buffer);
    this.glBufferData(this.gl.ARRAY_BUFFER, this.rawData, this.gl.STATIC_DRAW, dataOffset, dataLen);

    start += dataLen;

    this.meta.buffers.geometry = { numEl: 3, type: this.gl.FLOAT, offset: 0 };
    this.meta.buffers.texture = { numEl: 2, type: this.gl.FLOAT, offset: 12 };
    this.meta.buffers.normals = { numEl: 3, type: this.gl.FLOAT, offset: 20 };

    return start;
  }

  // ############################################
  build()
  {
    this.meta = {};
    this.rawData = new Uint8Array(this.rawData);

    this.meta.buffers = {};
    this.meta.buffers.geometry = null;
    this.meta.buffers.texture = null;
    this.meta.buffers.normals = null;

    // TODO: where should these go?
    this.meta.stride = 32;

    this.validateHeader(this.rawData.slice(0, 7));
    let start = 7;

    while(start < this.rawData.length)
    {
      const bufferType = this.rawData[start];

      if(bufferType === 0)
      {
        start = this.importBufType0(start);
        continue;
      }

      if(bufferType === 1)
      {
        start = this.importBufType1(start);
        continue;
      }

      throw new Error('BMESH: Unknown Buffer Type.');
    }

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged()
  {
    throw Error('rebuild request on binary mesh resource');
  }
}

/**/// ########################################################################
module.exports.rGL1BMesh = rGL1BMesh;
/**/// ########################################################################
