/** ###########################################################################
 * @file Allows us to create a resource chain
 * @author © Hugo Ferreira - 07.May.2022
 */
/**/// ########################################################################

import { Events } from './m.events.js';
import { Registry } from './m.registry.js';

// TODO: hash from string or binary, so that we can prevent reload if nothing changed
// TODO: on reload if failed, destroy resource/ unload resource

/**/// ########################################################################
const ResState = Object.freeze({
  CREATED: { name: 'CREATED' },
  LOADING: { name: 'LOADING' },
  LOADED: { name: 'LOADED' },
  PARSING: { name: 'PARSING' },
  PARSED: { name: 'PARSED' },
  VALIDATED: { name: 'VALIDATED' },
  DEPRECATED: { name: 'DEPRECATED' },
  FAILED: { name: 'FAILED' }
});

/**/// ########################################################################
// A resource container loads raw resource data from a specific source
class cResContainer
{
  constructor()
  {
    this.loaders = {};
  }

  /** ###########################################
   * Converts raw data to processed data
   * @param {string} prefix
   * @param {function} loadfunc
   */
  register(prefix, loadfunc)
  {
    this.loaders[prefix] = loadfunc;
  }

  /** ###########################################
  * Loads the raw data from a url prefixed source
  * @param {string} url
  * @returns buffer
  */
  load(url)
  {
    const parts = url.split('://');
    let prefix = parts[0];
    prefix = prefix.split('.')[0];
    const link = parts[1];
    const loader = this.loaders[prefix];

    if(typeof loader === 'function')
    {
      return loader(link);
    }

    throw new Error(`No Resource Container handler for ${prefix} for the url: ${url}`);
  }
}

/**/// ########################################################################
class cResourceHandlers
{
  // #####################################
  constructor()
  {
    this.loaders = {};
  }

  // #####################################
  register(type, loader)
  {
    if(this.loaders[type] === undefined)
    {
      this.loaders[type] = loader;
      return;
    }
    throw Error(`Resource Handler - Registering already registered loader for [${type}]`);
  }

  /**
   * From raw data returns a resource object
   * @param {string} type
   * @param {arraybuffer} rawData
   * @param {boolean} parse
   * @returns a new resource or null
   */
  load(type, rawData, parse)
  {
    const loader = this.loaders[type];

    if(typeof loader === 'function')
    {
      if(parse === undefined) parse = true;
      return loader({ parse, rawData });
    }

    return null;
  }
}

/**/// ########################################################################
class cResources
{
  // #####################################
  constructor()
  {
    this.containers = new cResContainer();
    this.handlers = new cResourceHandlers(); // load handlers
    this.pool = []; // all resources in the game
    this.pool_url = []; // all resources in the game by url
    this.pool_type = []; // all resources in the game by type
    this.pool_name = []; // all resources by name
    this.uid = 0; // unique id generator
    this.links = []; // contains parent to child links
    this.ilinks = []; // contains child to parent links
  }

  // #####################################
  urlSplit(url)
  {
    let parts = url.split('://');

    const u = {};
    u.path = parts[1];

    parts = parts[0].split('.');
    u.container = parts[0];
    u.type = parts[1];

    if(u.type === undefined)
    {
      u.type = u.path.split('.').pop();
    }

    return u;
  }

  // #####################################
  ilink(childID, parentID)
  {
    if(parentID === childID)
    {
      throw Error('parentID and childID cannot be the same');
    }

    if(typeof this.ilinks[childID] === 'undefined')
    {
      this.ilinks[childID] = [];
    }

    this.ilinks[childID].push(parentID);
  }

  // #####################################
  link(parentID, childID)
  {
    if(parentID === childID)
    {
      throw Error('parentID and childID cannot be the same');
    }

    if(typeof this.links[parentID] === 'undefined')
    {
      this.links[parentID] = [];
    }

    this.links[parentID].push(childID);
    this.ilink(childID, parentID);
  }

  // #####################################
  getNameFromUrl(url)
  {
    let ret = null;
    let parts = url.split('://');
    if(parts.length === 1)
    {
      ret ??= parts[0];
    }

    ret ??= parts[1];

    parts = ret.split('/');
    ret = parts[parts.length - 1];

    return ret;
  }

  // #####################################
  isLoadedName(name)
  {
    name = this.getNameFromUrl(name);
    if(name in this.pool_name)
    {
      return true;
    }

    return false;
  }

  // #####################################
  isLoadedUrl(url)
  {
    if(this.pool_url[url])
    {
      return true;
    }

    return false;
  }

  // #####################################
  parseUrlPrefix(url)
  {
    for (const [key, val] of Object.entries(Registry.resourcePathPrefix)) {
      url = url.replaceAll(`{#${key}#}`, val);
    }

    return url;
  }

  // #####################################
  /**
   * Loads a resource from a container
   * @param {string} url
   * @param {bool} forced
   * @returns a promise, resolves to the resource
   */
  load(url, forced)
  {
    url = this.parseUrlPrefix(url);
    if(!forced) forced = false;
    const _this = this;
    return new Promise(function(resolve, reject) {
      // we need to check this resource has already been loaded
      if(_this.isLoadedName(url) && !forced)
      {
        const resID = _this.pool_name[_this.getNameFromUrl(url)];
        const res = _this.pool[resID];

        // If it is loaded, but not yet parsed/processed, do it now:
        if(res.state === ResState.CREATED)
        {
          res.init();
          let count = 5000; // 5s
          const hInt = setInterval(() => {
            if(res.state !== ResState.CREATED)
            {
              clearInterval(hInt);
              return resolve(res);
            }
            count -= 25;
            if(count < 1)
            {
              clearInterval(hInt);
              return reject(new Error(`Failed to parse and load url:${url}`));
            }
          }, 25);
        }
        else
        {
          return resolve(res);
        }

        return;
      }

      // invoke the Container of the url to load the raw data
      _this.containers.load(url).then((rawData) => {
        const u = _this.urlSplit(url);

        // Pass the raw data to a loader and create the resource:
        const res = _this.handlers.load(u.type, rawData, true);
        if(res === null)
        {
          return reject(new Error(`Failed to load url:${url}`));
        }

        res.url = url;
        res.type = u.type;
        _this.register(res);

        return resolve(res);
      }).catch(e => reject(e));
    }); // promise
  }

  // ###########################################
  loadWithRawdata(url, rawData, parse)
  {
    // TODO: check if resource already loaded

    const _this = this;
    url = this.parseUrlPrefix(url);

    return new Promise(function(resolve, reject) {
      const u = _this.urlSplit(url);

      // Pass the raw data to a loader and create the resource:
      const res = _this.handlers.load(u.type, rawData, parse);
      if(res === null)
      {
        return reject(new Error(`Failed to load url:${url}`));
      }

      res.url = url;
      res.type = u.type;
      _this.register(res);

      return resolve(res);
    }); // promise
  }

  // ###########################################
  register(res)
  {
    // (by) id: (main pool)
    this.pool[res.id] = res;

    if(res.url)
    {
      // (by) url:
      this.pool_url[res.url] = res.id;

      // (by) name:
      this.pool_name[this.getNameFromUrl(res.url)] = res.id;
    }

    if(res.type !== undefined)
    {
      // (by) type:
      if(typeof this.pool_type[res.type] === 'undefined')
      {
        this.pool_type[res.type] = [];
      }

      // this.pool_type[res.type].push(res.id);
      this.pool_type[res.type][res.id] = res;
    }

    Events.trigger('resource_register', { res });
  }

  /** ###########################################
   * Iterates over all present resources
   * @param {function} cb
   * @returns nothing
   */
  forEach(cb)
  {
    if(typeof cb !== 'function') return;

    for (const [, res] of Object.entries(this.pool))
    {
      cb(res);
    }
  }

  /** ###########################################
   * Iterates over all Resources of a specific type
   * @param {string} type
   * @param {function} cb
   * @returns nothing
   */
  forEachType(type, cb)
  {
    if(typeof cb !== 'function') return;

    for (const [, res] of Object.entries(this.pool))
    {
      if(res.type !== type) continue;
      cb(res);
    }
  }

  // #####################################
  // replaces one resource with another
  replace(beforeID, newID)
  {
    const newRes = this.pool[newID];
    const ilinks = this.ilinks[beforeID];
    if(ilinks !== undefined)
    {
      for (const pID of ilinks)
      {
        // now go into links, delete old entry beforeID, add new newID
        const idx = this.links[pID].indexOf(beforeID);
        if (idx > -1) { this.links[pID].splice(idx, 1); }
        this.links[pID].push(newID);
        const pRes = this.pool[pID];
        // console.log(`invoking rebuild for resource: ${pRes.url}`);
        pRes.evSubChanged(newRes);
      }
    }

    // this.pool:
    delete this.pool[beforeID];

    // this.ilinks:
    // remove old entry, add new one
    // child is used by these parents
    {
      const tmp = this.ilinks[beforeID];
      if(tmp !== undefined)
      {
        delete this.ilinks[beforeID];
        this.ilinks[newID] = tmp;
      }
    }
  }

  // TODO: set old resource as non active
  // TODO: unload old resource
  // #####################################
  // trigering the reload, we probably only have the url, so
  // 1. search the pool for the resource that holds this url
  // 2. reload it into a new resource
  // 2b. if it failed, do not proceed
  // 3. replace the old resource id with this new id
  triggerResReload(url)
  {
    const _this = this;
    const originalResID = this.pool_name[this.getNameFromUrl(url)];
    if(originalResID === undefined) return false;
    console.log(`triggerResReload(${url})`);

    const beforeRes = this.pool[originalResID];
    beforeRes.state = ResState.DEPRECATED;

    this.load(url, true).then(res => {
      if(res.state === ResState.VALIDATED)
      {
        _this.replace(originalResID, res.id);
      }
    });
  }
} // cResources

export const Resources = new cResources();

/**/// ########################################################################
class cResourceBase
{
  constructor(options)
  {
    this.id = Resources.uid;
    Resources.uid++;

    options ??= {};

    if(!('parse' in options))
    {
      options.parse = false;
    }

    this.options = options;
    this.rawData = options.rawData;
    this.url = null;
    this.type = null;
    this.label = '';
    this.state = ResState.CREATED; // TODO: rename state to status
    this.deps = []; // TODO: give a better name
  }

  /**
   * Converts our rawdata from the fetch to a string.
   */
  rawToString()
  {
    if(typeof this.rawData !== 'string')
    {
      this.rawData = String.fromCharCode.apply(null, new Uint8Array(this.rawData));
    }
  }

  init()
  {
    const _this = this;
    this.load(this.preload()).then(vals => {
      _this.deps = vals;
      _this.build();
    });
  }

  // load one or more dependencies
  // TODO: rename to getValidDeps
  load(deps)
  {
    return new Promise(function(resolve, reject) {
      if(!deps) {
        return resolve([]);
      }

      if(typeof deps === 'string')
      {
        deps = [deps];
      }

      if(deps.length === 0)
      {
        return resolve([]);
      }

      const p = [];
      for (const url of deps)
      {
        p.push(Resources.load(url));
      }

      Promise.all(p).then((values) => {
        return resolve(values);
      }).catch(e => reject(e));
    }); // promise
  }

  // before building the resource determine dependencies, load them
  // must return an array of urls of resources to load.
  preload()
  {

  }

  // build, construct the resource
  // TODO: only invoke build if dependencies are loaded and valid
  // TODO: if deps fail, we fail
  build()
  {

  }

  // TODO: before the resource is terminated give it a chance to unload
  unload()
  {

  }

  // TODO: make sure resources above know about changes in this dependency
  changed()
  {
    const ilinks = Resources.ilinks[this.id];
    if(ilinks === undefined) return;
    for (const resID of ilinks)
    {
      const res = Resources.pool[resID];
      res.evSubChanged(this);
    }
  }

  // TODO: receives a notification of a changed dependency
  evSubChanged(dep)
  {
  }

  /**
   * Clones current resource and returns new resource with new name
   * @param {string} new resource name of clone
   * returns - a new resource
   */
  clone(name)
  {
  }
} // cResourceBase

/**/// ########################################################################
module.exports.cResourceBase = cResourceBase;
module.exports.ResState = ResState;
/**/// ########################################################################
