/* // #########################################################################
: m.chains.js
: 07.March.2022
: Chainable calls that are performance analised.
:
/**/// ########################################################################
/** @module chains */

import { Utils } from './m.utils.js';

/**/// ########################################################################
/**
 * Chains class, allows the registration of chain functions that are performance
 * measured.
 */
class cChains
{
  constructor()
  {
    this.list = [];
    this.chain = [];
  }

  /**
      * Adds a specific chain function.
      * @param {string} label
      * @param {function} func
      */
  add(label, func)
  {
    const entry = {};

    entry.active = true;
    entry.runs = 0;
    entry.maxtime = null;
    entry.mintime = null;
    entry.totaltime = 0;
    entry.label = label;
    entry.func = func;

    this.list[label] = entry;
  }

  /**
      * Runs a specific chain function.
      * @param {string} label
      * @param {Object} param
      */
  invoke(label, param)
  {
    const entry = this.list[label];
    if (!entry) return;

    /** @todo: add try/catch block here */
    /** @todo: add performance analisis here */
    const ts_before = Utils.getTimestamp();
    entry.func(param);
    const ts_after = Utils.getTimestamp();
    const elapsed = ts_after - ts_before;

    entry.runs++;
    entry.totaltime += elapsed;

    if (entry.maxtime === null) entry.maxtime = elapsed;
    if (entry.mintime === null) entry.mintime = elapsed;

    if (entry.maxtime < elapsed) entry.maxtime = elapsed;
    if (entry.mintime > elapsed) entry.mintime = elapsed;
  }

  /**
      * Removes a specific chain function.
      * @param {string} label
      */
  remove(label)
  {
    delete this.list[label];
  }

  /**
      * Sends a report on the running chains to the console
      */
  print_report()
  {
    console.log('############################################################# chains report:');

    for (const [, entry] of Object.entries(this.list))
    {
      const avg = Math.round(entry.totaltime / entry.runs);
      const max = Math.round(entry.maxtime);
      const min = Math.round(entry.mintime);
      console.log(`[${entry.label}] -> Max: ${max} Min: ${min} Runs: ${entry.runs} Avg: ${avg}`);
    }
  }

  /**
      * Allows a function to loop over all the chains
      * @param {function} f
      */
  for_each(f)
  {
    for (const [, entry] of Object.entries(this.list))
    {
      f(entry);
    }
  }
} // cChains

/**/// ########################################################################
export const Chains = new cChains();
/**/// ########################################################################
