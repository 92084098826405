/* // #########################################################################
: m.res.pak.js
: 02.Aug.2022
: Resource class for PAK bundle files
:
/**/// ########################################################################
/** @module res.pak.common */

import { cResourceBase, ResState } from '../m.resource.js';
import { cPakRead } from './m.pak.read.js';
import { Registry } from '../m.registry.js';
const { Resources } = require('../m.resource');

/**/// ########################################################################
class rPak extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.init();
  }

  // ############################################
  async build()
  {
    this.state = ResState.LOADING;
    this.active = false;

    this.pak = new cPakRead(this.rawData);
    this.pak.parse();

    if(this.pak.valid)
    {
      // PAK itself is valid, lets compare with our internal version:
      let validVersion = true;
      if(Registry.version.major !== this.pak.version[0])
      {
        validVersion = false;
      }

      if(Registry.version.minor !== this.pak.version[1])
      {
        validVersion = false;
      }

      if(Registry.version.revision !== this.pak.version[2])
      {
        validVersion = false;
      }

      if(!validVersion)
      {
        throw new Error(`PAK Version mismatch App:[${Registry.version.major}.${Registry.version.minor}.${Registry.version.revision}] PAK:[${this.pak.version[0]}.${this.pak.version[1]}.${this.pak.version[2]}]`);
      }

      // Now that we validate the pak version, lets actually load it:
      for(const value of this.pak.list)
      {
        const rawData = this.pak.buf.slice(value.start, value.end);

        // Load but do not parse immmediatly:
        Resources.loadWithRawdata(`pak://${value.label}`, rawData, false);
      }
    }

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged(dep)
  {
    console.log('object pak evSubChanged');
    console.log(dep.type);
    console.log(dep.url);

    this.state = ResState.LOADING;
    this.state = ResState.VALIDATED;
  }
}

/**/// ########################################################################
module.exports.rPak = rPak;
/**/// ########################################################################
