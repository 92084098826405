/* // #########################################################################
: m.res.shaderprogram.js
: 18.May.2022
: Resource class for Shader Program under WebGL2
:
/**/// ########################################################################
/** @module res.vertexshader.webgl2 */

import { Resources, cResourceBase, ResState } from '../m.resource.js'
import { Utils } from '../m.utils.js'
import { cShaderBabel } from '../m.shaderbabel.js'

/**/// ########################################################################
class rGL2ShaderProgram extends cResourceBase
{
  // ############################################
  constructor(options)
  {
    super(options);
    this.rawToString();
    this.gl = options.gl;
    this.shaderBabel = new cShaderBabel(this.gl);
    if(options.parse)
    {
      this.init();
    }
  }

  // ############################################
  // compute and return dependencies
  preload()
  {
    const deps = [];

    this.meta = {};
    this.meta.source = this.rawData;
    const obj = JSON.parse(this.rawData);

    // Import details:
    for (const [key, value] of Object.entries(obj)) {
      this.meta[key] = value;
    }

    deps.push(this.meta.vs);
    deps.push(this.meta.fs);

    return deps;
  }

  // ############################################
  build()
  {
    Resources.link(this.id, this.deps[0].id);
    Resources.link(this.id, this.deps[1].id);

    this.meta.res = {};

    this.build_step2();
  }

  // ############################################
  build_step2()
  {
    this.meta.res.vs = this.deps[0];
    this.meta.res.fs = this.deps[1];

    this.meta.program = this.gl.createProgram();
    this.gl.attachShader(this.meta.program, this.meta.res.vs.meta.shader);
    this.gl.attachShader(this.meta.program, this.meta.res.fs.meta.shader);
    this.gl.linkProgram(this.meta.program);

    if(!this.gl.getProgramParameter(this.meta.program, this.gl.LINK_STATUS))
    {
      const msg = 'Shader program failed to link. The error log is: ' + this.gl.getProgramInfoLog(this.meta.program);
      Utils.error(msg);
      throw new Error(msg);
    }

    this.shaderBabel.init(this.meta.program);

    this.state = ResState.VALIDATED;
    this.active = true;
  }

  // ############################################
  evSubChanged(dep)
  {
    this.state = ResState.LOADED;
    this.active = false;

    // Replace the dependency:
    if(dep && dep.type === 'vs')
    {
      this.deps[0] = dep;
    }

    if(dep && dep.type === 'fs')
    {
      this.deps[1] = dep;
    }

    // Rebuild the resource
    this.build_step2();

    // Signal upwards of changes:
    this.changed();
  }
}

/**/// ########################################################################
module.exports.rGL2ShaderProgram = rGL2ShaderProgram;
/**/// ########################################################################
