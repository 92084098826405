/* // #########################################################################
: m.render.webgpu.js
: 07.March.2022
: SubClass that manages draw calls using webGPU
:
/**/// ########################################################################
/** @module renderWebGPU */
import { cRenderBase } from './m.render.base.js';

/**/// ########################################################################
/**
 * Render Class specialized for WebGPU
 * @extends cRenderBase
 */
class cRenderWebGPU extends cRenderBase
{
  constructor(canvasEl)
  {
    super();
    this.id = 'webgpu';
    this.canvasEl = canvasEl;
  }

  /** ###########################################
   * init
   */
  async init()
  {
    this.adapter = await navigator.gpu.requestAdapter();
    if(!this.adapter) throw new Error('Failed to request WebGPU adapter.');

    this.device = await this.adapter.requestDevice();
    if(!this.device) throw new Error('Failed to request WebGPU logical device.');

    this.device.lost.then(() => {
      console.error('WebGPU cannot be initialized - Device has been lost');
    });

    this.context = this.canvasEl.getContext('webgpu');
    if(!this.context)
    {
      console.error('WebGPU cannot be initialized - Canvas does not support WebGPU');
      return;
    }

    const devicePixelRatio = window.devicePixelRatio || 1;
    const presentationSize = [
      this.canvasEl.clientWidth * devicePixelRatio,
      this.canvasEl.clientHeight * devicePixelRatio];

    const presentationFormat = this.context.getPreferredFormat(this.adapter);

    this.context.configure({
      device: this.device,
      format: presentationFormat,
      size: presentationSize
    });
  }

  /** ###########################################
   * clear
   */
  clear()
  {
  }

  render()
  {
  }
}

/**/// ########################################################################
module.exports.cRenderWebGPU = cRenderWebGPU;
/**/// ########################################################################
