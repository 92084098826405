/* // #########################################################################
: m.shaderbabel.js
: 01.Sep.2022
: Shader interface to facilitate setting variables.
:
/**/// ########################################################################

/**/// ########################################################################
const typeEnum = {
  FLOAT_VEC2: 0x8B50,
  FLOAT_VEC3: 0x8B51,
  FLOAT_VEC4: 0x8B52,
  INT_VEC2: 0x8B53,
  INT_VEC3: 0x8B54,
  INT_VEC4: 0x8B55,
  BOOL: 0x8B56,
  BOOL_VEC2: 0x8B57,
  BOOL_VEC3: 0x8B58,
  BOOL_VEC4: 0x8B59,
  FLOAT_MAT2: 0x8B5A,
  FLOAT_MAT3: 0x8B5B,
  FLOAT_MAT4: 0x8B5C,
  SAMPLER_2D: 0x8B5E,
  SAMPLER_CUBE: 0x8B60
};

/**/// ########################################################################
class cShaderBabel
{
  constructor(gl)
  {
    this.gl = gl;
    this.program = null;
  }

  getKeyByValue(obj, value)
  {
    return Object.keys(obj).find(key => obj[key] === value);
  }

  init(program)
  {
    this.program = program;

    this.vars = [];

    // ##################################################### Attributes
    const numAttributes = this.gl.getProgramParameter(program, this.gl.ACTIVE_ATTRIBUTES);
    for(let i = 0; i < numAttributes; ++i)
    {
      const a = this.gl.getActiveAttrib(program, i);
      const atype = this.getKeyByValue(typeEnum, a.type);
      const handle = this.gl.getAttribLocation(program, a.name);

      // type (attribute), vartype, label, handle
      const entry = { type: 'at', vartype: atype, label: a.name, handle };
      this.vars.push(entry);
    }

    // ####################################################### Uniforms
    const numUniforms = this.gl.getProgramParameter(program, this.gl.ACTIVE_UNIFORMS);
    for(let i = 0; i < numUniforms; ++i)
    {
      const u = this.gl.getActiveUniform(program, i);
      const utype = this.getKeyByValue(typeEnum, u.type);
      const handle = this.gl.getUniformLocation(program, u.name);

      // type (uniform), vartype, label, handle
      const entry = { type: 'un', vartype: utype, label: u.name, handle };
      this.vars.push(entry);
    }
  }

  // ############################################
  getMethodFromType(type, vartype)
  {
    if(type === 'un')
    {
      switch(vartype)
      {
        case 'FLOAT_MAT4': return 'uniformMatrix4fv({#a#}, false, {#b#})';
        case 'FLOAT_MAT3': return 'uniformMatrix3fv({#a#}, false, {#b#})';
        case 'FLOAT_VEC3': return 'uniform3f({#a#}, {#b#}[0], {#b#}[1], {#b#}[2])';
      }
    }
  }

  // ############################################
  forEach(cb)
  {
    for(const [, value] of Object.entries(this.vars))
    {
      cb(value);
    }
  }

  // ############################################
  getvar(label)
  {
    for(const [, value] of Object.entries(this.vars))
    {
      if(value.label === label) return value;
    }

    return null;
  }
}

/**/// ########################################################################
module.exports.cShaderBabel = cShaderBabel;
